import "./focusTracker.scss";

import { DS } from "~/libs";

let oldAnimationDuration: string;
let oldTransitionDuration: string;
let oldAnimation: string;
let oldTransition: string;

type IListScrollElement = DS.IListComponent<DS.Identifiable, DS.IView> & { scrollElement: HTMLElement };

interface IFocusTrackerOptionnalParams {
  /**
   * how much bigger should the focus overlay be
   * @default 0
   */
  expandBy?: number;
  /**
   * If the focus should not have any transform. This can be helpful if it
   * is not positionned correctly.
   * @default true
   */
  shouldTransform?: boolean;
  /**
   * Transformation style name applied to the focus tracker
   * @default normal
   */
  style?: "normal" | "hero";
}

export class FocusTracker extends DS.View {
  /**
   * will track the focus on a given list with an overlay element
   * @param list the list to track focus on
   * @param focusRectStyle the style of the focus rect
   * @param targetId (optional, defaults to the list element itself) the id of the child element the focus needs to match
   * @param optional (optional) Specific options for the focusTracker
   */
  constructor(
    list: DS.IListComponent<DS.Identifiable, DS.IView>,
    focusRectStyle: string,
    targetId?: string,
    optional?: IFocusTrackerOptionnalParams
  ) {
    super(focusRectStyle, focusRectStyle);
    list.rootElement.appendChild(this.rootElement);
    this.rootElement.style.willChange = "transform";
    this.rootElement.hidden = true;

    const expandBy = optional?.expandBy ?? 0;
    const shouldTransform = optional?.shouldTransform ?? true;
    const style = optional?.style ?? "normal";

    const adjustFocus = (view?: DS.IView, animate?: boolean) => {
      if (view && DS.DOMHelper.isInDOM(view.rootElement)) {
        oldAnimationDuration = oldAnimationDuration ?? this.rootElement.style.animationDuration;
        oldTransitionDuration = oldTransitionDuration ?? this.rootElement.style.transitionDuration;
        oldAnimation = oldAnimation ?? this.rootElement.style.animation;
        oldTransition = oldTransition ?? this.rootElement.style.transition;

        const targetElement =
          (Array.from(view.rootElement.children).find(
            child => targetId != null && child.classList.contains(targetId)
          ) as HTMLElement) ?? view.rootElement;

        if (animate == null || !animate) {
          this.rootElement.style.animationDuration = "initial";
          this.rootElement.style.transitionDuration = "initial";
          this.rootElement.style.animation = "initial";
          this.rootElement.style.transition = "initial";
        } else {
          this.rootElement.style.animationDuration = oldAnimationDuration;
          this.rootElement.style.transitionDuration = oldTransitionDuration;
          this.rootElement.style.animation = oldAnimation;
          this.rootElement.style.transition = oldTransition;
        }

        this.rootElement.hidden = false;
        this.rootElement.style.position = "relative"; // coordinates directly in the parent, relative to work in RTL layout
        this.rootElement.style.width = `${targetElement.offsetWidth + expandBy}px`;
        this.rootElement.style.height = `${targetElement.offsetHeight + expandBy}px`;
        if (shouldTransform) {
          const translateX =
            targetElement.offsetLeft +
            (-expandBy +
              (view.rootElement._dsTranslate?.x ?? 0) +
              ((list as IListScrollElement).scrollElement._dsTranslate?.x ?? 0));
          const translateY =
            -expandBy +
            targetElement.offsetTop +
            (view.rootElement._dsTranslate?.y ?? 0) +
            ((list as IListScrollElement).scrollElement._dsTranslate?.y ?? 0);

          if (style === "normal") {
            this.rootElement.style.transform = `translate3d(${translateX}px, ${translateY}px, 0) `;
          } else if (style === "hero") {
            this.rootElement.style.transform = `matrix(0.97, -0.26, 0.26, 0.97, ${translateX}, ${translateY})`;
          }
        }
      }
      this.rootElement.hidden = view === undefined;
    };
    // initial setup
    adjustFocus(list.focusedView$.value, false);
    // and adjust when changes
    list.focusedView$.didChange(
      (view, oldView) => adjustFocus(view, !list.focusedFromMouse$.value && oldView !== undefined),
      this,
      true
    );

    // also readjust when getting the focus
    list.focused$.didChange(focused => {
      if (focused) adjustFocus(list.focusedView$.value, false);
    }, this);

    list.shown$.didChange(shown => {
      if (shown) adjustFocus(list.focusedView$.value, false);
    }, this);
    let scrollTimeout: number | undefined;
    list.scrollPosition$.didChange(() => {
      this.rootElement.hidden = true;
      this.rootElement.style.opacity = "0";
      window.clearTimeout(scrollTimeout);
      // hardcoded time, kinda hacky
      scrollTimeout = window.setTimeout(() => {
        this.rootElement.style.opacity = "";
        adjustFocus(list.focusedView$.value, false);
      }, 150);
    }, this);
  }
  /**
   * This function modify the focus style
   * @param focusStyle string
   */
  setFocusRectStyle(focusStyle: string) {
    this.rootElement.setAttribute("class", focusStyle);
  }
}
