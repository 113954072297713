import { APIAuvio } from "~/datas/api/apiAuvio";
import { APIGigyaOIDC } from "~/datas/api/apiGigyaOIDC";
import { GIGYA } from "~/utils/gigya";
import { GigyaUserInfo, ParentalControlLevel } from "~/utils/gigya/models";
import { Rating } from "~/utils/rtbf/models";

import { hoursToMilliseconds } from "./time";

class _ParentalControlHelper {
  private static _instance?: _ParentalControlHelper;
  PARENTAL_DISABLED_HOURS = 3;
  defaultParentalControl = ParentalControlLevel.sixteen;

  static get instance() {
    return (_ParentalControlHelper._instance = _ParentalControlHelper._instance ?? new _ParentalControlHelper());
  }

  currentParentalControl(): ParentalControlLevel {
    return APIGigyaOIDC.userInfo$.value?.data?.parentalControl ?? this.defaultParentalControl;
  }

  /**
   * Check if this user is authorized with parental control
   * @param rating The rating of the content
   */
  async isParentalAuthorized(rating: Rating): Promise<boolean> {
    await APIGigyaOIDC.updateUserInfo();
    return this._isParentalAuthorized(
      rating,
      APIGigyaOIDC.userInfo$.value,
      ParentalControlHelper.defaultParentalControl,
      Date.now()
    );
  }

  /**
   * Pure function to check if it's parental authorized
   * @param rating The rating of the content
   */
  private _isParentalAuthorized(
    rating: Rating,
    userInfo: GigyaUserInfo | undefined,
    defaultParentalControl: ParentalControlLevel,
    date: number
  ): boolean {
    const { pin, parentalControl, parentalControlDisabled } = userInfo?.data ?? {};
    if (pin === undefined && rating === defaultParentalControl) return false;
    if (pin === undefined) return true;
    // Controle parental desactive sur le compte
    if (parentalControl === ParentalControlLevel.all) return true;
    if (new Date(parentalControlDisabled ?? 0).getTime() > date) return true;
    // Controle parental pas desactivé mais rating -16, forcément impossible
    if (rating === ParentalControlLevel.sixteen) return false;
    if (rating === ParentalControlLevel.twelve && parentalControl === ParentalControlLevel.twelve) return false;
    return true;
  }

  async setPin(pin: string) {
    if (pin === undefined) {
      await APIAuvio.createPinCode(pin);
    } else {
      await APIAuvio.updateParental(pin, this.currentParentalControl(), new Date(0));
    }
    await APIGigyaOIDC.updateUserInfo();
  }

  async disableParentalControl() {
    if (APIGigyaOIDC.userInfo$.value?.data?.pin === undefined) return;
    const dateOffset = new Date();
    dateOffset.setHours(dateOffset.getHours() + this.PARENTAL_DISABLED_HOURS);
    await APIAuvio.updateParental(
      APIGigyaOIDC.userInfo$?.value?.data?.pin,
      APIGigyaOIDC.userInfo$?.value?.data?.parentalControl,
      dateOffset
    );
    await APIGigyaOIDC.updateUserInfo();
  }

  async setParentalControlOptimistic(level: ParentalControlLevel) {
    if (APIGigyaOIDC.userInfo$.value?.data?.pin !== undefined) {
      // this.parentalControl$.value = level;
      await APIAuvio.updateParental(APIGigyaOIDC.userInfo$.value?.data?.pin, level, new Date(0));
      await APIGigyaOIDC.updateUserInfo();
    }
  }

  /**
   * Use for test. Put a very old date to parental control.
   */

  async activateParentalControl() {
    if (APIGigyaOIDC.userInfo$.value?.data?.pin === undefined) return;
    const oldDate = new Date();
    oldDate.setTime(oldDate.getTime() - hoursToMilliseconds(this.PARENTAL_DISABLED_HOURS + 1));
    await APIAuvio.updateParental(
      APIGigyaOIDC.userInfo$?.value?.data?.pin,
      APIGigyaOIDC.userInfo$?.value?.data?.parentalControl,
      oldDate
    );
    await APIGigyaOIDC.updateUserInfo();
  }

  /**
   * Update in an optmisitic way the parental control
   * TODO: Mutualize the function argument type with the non optimistic version ?
   */
  async updateParental(
    pin: string | undefined,
    parentalControl: GIGYA.ParentalControlLevel,
    parentalControlDisabled?: Date
  ) {
    await APIAuvio.updateParental(pin, parentalControl, parentalControlDisabled);
    await APIGigyaOIDC.updateUserInfo();
    // Update locally
    // Make the API call
    // Define a way to catch the error and reset pinCode if necessary
  }
}

export const ParentalControlHelper = _ParentalControlHelper.instance;
