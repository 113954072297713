import "./genericPage.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { APIAuvio } from "~/datas/api/apiAuvio";
import { DS } from "~/libs";
import { TrackingHelper } from "~/tools/trackingHelper";

import { TitleContentView } from "../../components/contents/common/titleContentView";
import { ExplorerContentView } from "../../components/contents/explorer/explorerContentView";
import { LiveContentView } from "../../components/contents/live/liveContentView";
import { MediaContentView } from "../../components/contents/media/mediaContentView";
import { MosaicContentView } from "../../components/contents/mosaic/mosaicContentView";
import { PremiumContentView } from "../../components/contents/premium/premiumContentView";
import { ProductContentView } from "../../components/contents/product/productContentView";
import { ProgramContentView } from "../../components/contents/program/programContentView";
import { BannerWidgetView } from "../../components/widgets/banner/bannerWidgetView";
import { CategoryWidgetView } from "../../components/widgets/category/categoryWidgetView";
import { ChannelWidgetView } from "../../components/widgets/channel/channelWidgetView";
import { EmptyWidgetView } from "../../components/widgets/empty/emptyWidgetView";
import { emptySearchWidget, EmptySearchWidgetView } from "../../components/widgets/emptySearch/emptySearchWidgetView";
import { FavoriteWidgetView } from "../../components/widgets/favorite/favoriteWidgetView";
import { HeroWidgetView } from "../../components/widgets/hero/heroWidgetView";
import { HistoryWidgetView } from "../../components/widgets/history/historyWidgetView";
import { MediaFlexWidgetView } from "../../components/widgets/mediaFlex/mediaFlexWidgetView";
import { MediaPremiumWidgetView } from "../../components/widgets/mediaPremium/mediaPremiumWidgetView";
import { MosaicWidgetType, MosaicWidgetView } from "../../components/widgets/mosaic/mosaicWidgetView";
import { ProductPremiumWidgetView } from "../../components/widgets/productPremium/productPremiumWidgetView";
import { ProgramWidgetView } from "../../components/widgets/program/programWidgetView";
import { PromoBoxWidgetView } from "../../components/widgets/promobox/promoBoxWidgetView";
import { QuickLinkWidgetView } from "../../components/widgets/quicklink/quickLinkWidgetView";
import { RadioLiveWidgetView } from "../../components/widgets/radio/radioLiveWidgetView";
import { TabWidgetView } from "../../components/widgets/tab/tabWidgetView";
import { TrailerWidgetView } from "../../components/widgets/trailer/trailerWidgetView";
import { U2CWidgetView } from "../../components/widgets/u2c/u2cWidgetView";
import { WidgetView } from "../../components/widgets/widgetView";
import { Config } from "../../config";
import { RootMenuPage } from "../../rootMenu/rootMenuPage";
import { areArraysEqual } from "../../tools/arrayHelper";
import { RTBF } from "../../utils/rtbf";
import { MediaType, Page, PageContent, PageTypeWithContent, Widget } from "../../utils/rtbf/models";

// This is for our custom elements in the GenericPage modelSource
export type CustomWidget = {
  id: string;
  customType: "EMPTY_SEARCH" | "EMPTY";
};

function isCustomWidget(data: any): data is CustomWidget {
  return (data as CustomWidget)?.customType !== undefined;
}

/**
 * Generic page content to display in generic page, mostly widgets
 * but we will manage specific pages here
 */
class GenericContent extends AcceptsMouseFocusView {
  private _modelSource: DS.ModelSource<Widget | PageContent | CustomWidget>;
  private _views$ = new DS.Listenable<WidgetView[]>([]);

  page?: Page;
  content?: PageContent;

  private _mosaicWidgetType?: MosaicWidgetType;
  private _path: string;

  constructor(path: string, mosaicWidgetType?: MosaicWidgetType) {
    super("GenericContent");
    this._mosaicWidgetType = mosaicWidgetType;
    this._path = path;

    this.delegate = DS.createListComponent(
      {
        id: "GenericContentList",
        className: "GenericContentList",
        modelSource$: (this._modelSource = new DS.ModelSource<Widget | PageContent | CustomWidget>(
          this._content(path)
        )),
        viewFactory: (item, index) => {
          // Checking if the item is a custom widget
          if (isCustomWidget(item)) {
            return this._createCustomWidget();
          }
          // Checking if the item is a widget
          if (RTBF.isWidget(item)) {
            return this._createWidget(item, index);
          }
          // Otherwise it's a content
          return this._createContent(item);
        },
        scrollingMode: { type: DS.ScrollingType.elasticWindow, horizontal: false },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && {
          focusRange: "visible",
          wheelScrollBy: 432,
        },
      },
      list => {
        if (list.focusedIndex$.value === undefined) {
          void list.setFocusOnIndex(0);
        }
      }
    );

    // Everytime the view change, we filter the page
    // because a widget can be empty.
    this._views$.didChange(newValue => {
      const view = newValue[newValue.length - 1];
      if (view.modelSource !== undefined) {
        const release = view.modelSource.isComplete$.didChange(() => {
          this.filterPage();
          release();
        }, this);
      }
    }, this);
  }

  /**
   * This function ask for the widgets list and update the widgets$
   * listenable. This will then refresh the list.
   * @param path The API path to fetch the widgets list.
   */
  private async _content(path: string): Promise<(PageContent | Widget)[]> {
    this.page = await APIAuvio.page(path);

    const content: (PageContent | Widget)[] = [];

    // Add content if one of those conditions are met :
    // - If the title is not empty
    // - If it's a PageTypeWithContent, add page data content (otherwise some pages doesn't work well, like search or filtered mosaic, because their title can be empty)
    if (this.page.data.content.title !== "" || this.page.data.content.pageType in PageTypeWithContent.enum === true) {
      this.content = this.page.data.content;
      content.push(this.page.data.content);
    }

    content.push(...this.page.data.widgets);

    // Init trackPageView
    this.trackPageView();

    return content;
  }

  private _createCustomWidget() {
    return new EmptySearchWidgetView();
  }

  /**
   * THis function create a content.
   * @param content Page content model
   * @returns ContentView child
   */
  private _createContent(content: PageContent) {
    this.content = content;

    switch (content.pageType) {
      case "PREMIUM":
        this._background(content.background.xl, "full-width");
        return new PremiumContentView(content);
      case "PREMIUM_PRODUCT":
        this._background(content.background.xl, "full-width");
        return new ProductContentView(content, this.page?.data.widgets);
      case "LIVE":
        this._backgroundWithOverlay(content.background.xl, content.type);
        return new LiveContentView(content);
      case "MEDIA":
        if (content.background) this._backgroundWithOverlay(content.background.xl, content.type);
        return new MediaContentView(content);
      case "CHANNEL":
      case "CATEGORY":
      case "PATH":
        return new TitleContentView(content.title);
      case "MOSAIC":
        return new MosaicContentView(content, this.page?.data.widgets[0], widgets => {
          this._displayFilteredWidget(widgets);
        });
      case "EXPLORER":
        return new ExplorerContentView(content, widgets => {
          this._displaySearch(widgets);
        });
      case "PROGRAM":
        if (content.background) this._backgroundWithOverlay(content.background.xl, content.type);
        return new ProgramContentView(content);
    }
  }

  /**
   * This function create a widget, based on a widget model.
   * @param widget The widget model
   * @returns A widget view
   */
  private _createWidget(widget: Widget, index: number): WidgetView {
    widget.index = index;
    switch (widget.type) {
      case "MEDIA_LIST":
        return this._registerWidget(new MediaFlexWidgetView(widget));
      case "BANNER":
        return this._registerWidget(new BannerWidgetView(widget));
      case "CATEGORY_LIST":
        return this._registerWidget(new CategoryWidgetView(widget));
      case "RADIO_LIVE":
        return this._registerWidget(new RadioLiveWidgetView(widget));
      case "PROGRAM_LIST":
        return this._registerWidget(new ProgramWidgetView(widget));
      case "CHANNEL_LIST":
        return this._registerWidget(new ChannelWidgetView(widget));
      case "MEDIA_PREMIUM_LIST":
        return this._registerWidget(new MediaPremiumWidgetView(widget));
      case "MEDIA_TRAILER":
        return this._registerWidget(new TrailerWidgetView(widget));
      case "PRODUCT_PREMIUM_LIST":
        return this._registerWidget(new ProductPremiumWidgetView(widget));
      case "PROMOBOX":
        return this._registerWidget(new PromoBoxWidgetView(widget));
      case "MOSAIC":
        return this._registerWidget(
          new MosaicWidgetView(
            widget,
            RTBF.pageMosaicContentHasFilters(this.page?.data?.content),
            this._mosaicWidgetType
          )
        );
      case "TAB_LIST":
        return this._registerWidget(new TabWidgetView(widget));
      case "FAVORITE_LIST":
        return this._registerWidget(new FavoriteWidgetView(widget));
      case "FAVORITE_PROGRAM_LIST":
        return this._registerWidget(new FavoriteWidgetView(widget));
      case "ONGOING_PLAY_HISTORY":
        return this._registerWidget(new HistoryWidgetView(widget));
      case "PLAY_HISTORY":
        return this._registerWidget(new HistoryWidgetView(widget));
      case "QUICK_LINK":
        return this._registerWidget(new QuickLinkWidgetView(widget));
      case "HERO_LIST":
        return this._registerWidget(new HeroWidgetView(widget));
      case "MEDIA_U2C":
        return this._registerWidget(new U2CWidgetView(widget));
    }
    // !!! these widget types are not handle in generic : "PREMIUM" "FAVORITE_PREMIUM_LIST"  "FAVORITE_LIST_PROGRAM"
    return new EmptyWidgetView(widget);
  }

  /**
   * This function put a widget in an array and returns it.
   * @param widget A widget view.
   * @returns The widget.
   */
  private _registerWidget(widget: WidgetView) {
    this._views$.value = this._views$.value.concat([widget]);
    return widget;
  }

  /**
   * This function is triggered everytime a widget changes.
   * This function check if there are empty widget, and remove them.
   * This can be useful in case of bugs in the API, or if there are no favorites
   * for example.
   */
  filterPage() {
    const value = this._modelSource.value.filter(widget => {
      // Check if there's a view with this widget
      const view = this._views$.value.find(view => view.id === widget.id);
      if (view && view.modelSource !== undefined && view.modelSource.isComplete$.value) {
        // Check if the view is completed and with elements
        return view.modelSource.value.length > 0;
      }
      // If there's no view it might no be created, so return true.
      return true;
    });
    // Check if the arrays are not equals.
    if (!areArraysEqual(value, this._modelSource.value)) this._modelSource.value = value;
  }

  private _background(url: string, type: "" | "full-width" = "") {
    switch (type) {
      case "full-width":
        this.rootElement.style.background = `url("${url}") no-repeat`;
        this.rootElement.style.backgroundPosition = "top right";
        this.rootElement.style.backgroundSize = "1920px";
        break;
      default:
        this.rootElement.style.background = `url("${url}") 50% 50% / cover no-repeat`;
    }
  }

  private _backgroundWithOverlay(url: string, mediaType: MediaType | string) {
    const audioFormat: (string | MediaType)[] = ["AUDIO", "AUDIO_SERIE", "AUDIO_SHOW"];
    const overlay = audioFormat.includes(mediaType)
      ? require("@assets/images/detailed/detailed_page_overlay_podcast.png")
      : require("@assets/images/detailed/detailed_page_overlay.png");

    this.rootElement.style.background = `url("${overlay}") no-repeat, url("${url}") no-repeat`;
    this.rootElement.style.backgroundPosition = "top right, top right";
    this.rootElement.style.backgroundSize = "1920px 1080px, 1920px 1080px";
  }

  /**
   * Display the search results. This is used as a callback.
   * @param result The result of a search
   */
  private _displaySearch(result: Widget[]) {
    let value = this._modelSource.value.filter(pageItem =>
      // keep content and remove widgets
      Object.keys(pageItem).includes("pageType")
    );
    if (result.length > 0) {
      value = [...value, ...result];
    } else {
      value = [...value, emptySearchWidget];
    }
    this._modelSource.value = value;
  }

  /**
   * Display the filtered widget mosaic. This is used as a callback.
   * @param result The result of a filter
   */
  private _displayFilteredWidget(result: Widget) {
    let value = this._modelSource.value.filter(pageItem =>
      // keep content and remove widgets
      Object.keys(pageItem).includes("pageType")
    );

    value = [...value, ...[result]];
    this._modelSource.value = value;
  }

  /**
   * Track the page with all data
   */
  trackPageView() {
    TrackingHelper.track({
      event_name: "page_view",
      path: this._path,
      chartbeat:
        this.content !== undefined
          ? {
              asset: this.content,
              meta: this.page?.meta.chartbeat,
            }
          : undefined,
      gemius: this.page?.meta.gemius,
      gtag: {
        page_title: this.page?.meta.seo?.title,
        page_id: this.page?.data.id,
        page_type: this.page?.data.pageType,
        page_referrer: TrackingHelper.pageReferrer,
      },
    });
  }
}

/**
 * Generic pages provisioned from endpoint pages RTBF
 * WITHOUT left menu
 */
export class GenericPageFull extends DS.View implements DS.IPage {
  private _content: GenericContent;

  constructor(path: string, mosaicWidgetType?: MosaicWidgetType) {
    super("GenericPage", "GenericPageFull");
    this.delegate = this._content = new GenericContent(path, mosaicWidgetType);
  }

  onShown() {
    // Tracking page on shown when content already exists, meaning on back for GenericPageFull
    this._content.trackPageView();
  }
}

/**
 * Generic pages provisioned from endpoint pages RTBF
 */
export class GenericPage extends RootMenuPage implements DS.IPage {
  private _content?: GenericContent;
  // To keep for a spinner
  private _loaded = false;
  path = "";

  constructor(path: string, mosaicWidgetType?: MosaicWidgetType) {
    super("GenericPage", "RootMenuPage", () => {
      this._loaded = true;
      this.path = path;
      return (this._content = new GenericContent(path, mosaicWidgetType));
    });
  }

  onHidden() {
    if (this._content?.page?.meta.seo?.title !== undefined) {
      TrackingHelper.pageReferrer = this._content.page?.meta.seo?.title;
    }
  }

  onShown() {
    // Tracking page on shown when content already exists, meaning on page already loaded for GenericPage, on back
    super.onShown();
    if (this._content !== undefined) {
      this._content.trackPageView();
    }
  }
}
