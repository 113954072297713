import "./parentalButtonView.scss";

import { DS } from "~/libs";
import { ParentalControlHelper } from "~/tools/parentalControlHelper";

import { navigationStack } from "../../../main";
import { ValidateLevelParentalPopupPage } from "../../../pages/parentalControl/parentalPopupPage";
import { ITextElement, TextHelper } from "../../../tools/textHelper";
import { GIGYA } from "../../../utils/gigya";
import { AcceptsMouseFocusView } from "../common/mouseSupport/acceptsMouseFocusView";

export class ParentalButtonView extends AcceptsMouseFocusView {
  label: string;
  button: ITextElement;

  constructor(disabled = true, label?: string) {
    super("ParentalButtonView", "parentalButtonView");
    this.label = "ParentalButton";
    this.button = TextHelper.createTextElement({
      rootElement: this.rootElement,
      classname: "parentalButtonContainer",
      content: t(label ?? "parentalControl.confirmButtonText"),
    });
    if (disabled) this.button.get().classList.add("disabled");
  }

  async action() {
    return;
  }

  rejectsFocus() {
    if (this.button.get().classList.contains("disabled")) return true;
    return false;
  }

  enable() {
    this.button.get().classList.remove("disabled");
  }

  disable() {
    this.button.get().classList.add("disabled");
  }
}

export class ModifyLevelParentalButtonView extends ParentalButtonView {
  newParentalControl$: DS.Listenable<GIGYA.ParentalControlLevel>;

  constructor(newParentalControl$: DS.Listenable<GIGYA.ParentalControlLevel>) {
    super(false);
    this.newParentalControl$ = newParentalControl$;
  }

  async action() {
    const oldID = ParentalControlHelper.currentParentalControl();
    const newId = this.newParentalControl$.value;
    if (
      newId === oldID ||
      (newId === GIGYA.ParentalControlLevel.sixteen && oldID === GIGYA.ParentalControlLevel.all) ||
      (newId === GIGYA.ParentalControlLevel.twelve &&
        (oldID === GIGYA.ParentalControlLevel.sixteen || oldID === GIGYA.ParentalControlLevel.all))
    ) {
      navigationStack.removePage(navigationStack.topPage);
      // reseting the disable of the  3 hour of parental control
      await ParentalControlHelper.updateParental(undefined, newId, new Date(0));
    } else {
      navigationStack.removePage(navigationStack.topPage);
      navigationStack.pushPage(new ValidateLevelParentalPopupPage(newId));
    }
  }
}

export class SendPinButtonView extends AcceptsMouseFocusView {
  constructor() {
    super("SendPinButtonView", "sendPinButtonView");
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "parentalButtonContainer",
      innerText: t("parentalControl.sendPinButtonText"),
    });
  }
}
