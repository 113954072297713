import "./promoBoxWidgetView.scss";

import { DS } from "dslib-tv";

import { TrailerPlayer } from "~/components/player/trailer/trailerPlayer";
import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { APIAuvio } from "~/datas/api/apiAuvio";
import { IPoint } from "~/libs/exports";
import { PlayerPage } from "~/pages/player/playerPage";
import { isTizen8 } from "~/tools/deviceHelper";
import { playerAudio, PlayerState, playerVideo } from "~/tools/player";
import { PromoBoxCard, Stamp, Widget, WidgetPromoBox } from "~/utils/rtbf/models";

import { Config } from "../../../config";
import { navigationStack } from "../../../main";
import { GenericPage } from "../../../pages/generic/genericPage";
import { openKids } from "../../../pages/onBoarding/onBoardingPage";
import { FocusTracker } from "../../focusTracker";
import { LoadingTile } from "../../tiles/loading/loadingTile";
import { CarrouselTrackerType, CarrouselTrackerView } from "../../views/carrouselTrackerView/carrouselTrackerView";
import { StampView } from "../../views/stampView/stampView";
import { WidgetView } from "../widgetView";

class PromoBoxTileView extends AcceptsMouseFocusView {
  constructor(promoBox: PromoBoxCard) {
    super("", "promoBoxTile");

    const cardContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "cardContainer",
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileCover",
      style: {
        backgroundImage: `url(${promoBox.image.xl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileFade",
      style: {
        backgroundImage: `linear-gradient(90deg, ${promoBox.backgroundColor} 0%, ${promoBox.backgroundColor} 37%, transparent 50%)`,
      },
    });
    const infosContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainer,
      className: "tileInfos",
      style: {
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    if (promoBox.promoType === "LIVE") {
      const stamp: Stamp = {
        label: "DIRECT",
        textColor: "#fff",
        backgroundColor: "#E55232",
      };
      infosContainer.appendChild(new StampView(stamp).rootElement);
    }

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: infosContainer,
      className: "tileTitle",
      innerText: promoBox.title,
    });
    const subtitleElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: infosContainer,
      className: "tileSubtitle",
      innerText: promoBox.subtitle,
    });
    if (promoBox.promoType === "LIVE") {
      subtitleElement.style.color = "#E55232";
    }

    if (promoBox.description !== null) {
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: infosContainer,
        className: "tileDescription",
        innerText: promoBox.description,
      });
    }
  }
}

export class PromoBoxWidgetView extends WidgetView {
  private _list: DS.IListComponent<PromoBoxCard, PromoBoxTileView>;
  private _modelSource$: DS.ModelSource<PromoBoxCard>;
  private _carrouselTypes: CarrouselTrackerType[] = [];
  private _focusTracker?: FocusTracker;
  private _chipList?: CarrouselTrackerView;
  private _checkTimer?: number;
  private _scrollDuration = 10;
  private _scrollIndex = 0;
  private _playVideoTimeout: number | undefined;
  private _nextPromoTimeout: number | undefined;
  private _destroyPlayerTimeout: number | undefined;
  private _player: TrailerPlayer | undefined;
  private _isFocused = false;
  private _isVisible = true;

  constructor(widget: Widget) {
    super(widget.id, `promoBoxWidget ${isTizen8() === false ? "opacityAnimations" : ""}`);
    this._setAnalytics(widget);
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "promoboxContainer",
    });
    // Widget list of content
    this.delegate = this._list = DS.createListComponent(
      {
        id: `${widget.id}/list`,
        className: "widgetList widgetListPromobox",
        modelSource$: (this._modelSource$ = new DS.ModelSource(this._content(widget))),
        viewFactory: promoBox => {
          return new PromoBoxTileView(promoBox);
        },
        loadingPlaceholderFactory: () => new LoadingTile(),
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport,
        onSelect: (card, index) => {
          super._analyticsCards({
            action: "click",
            card_order: index,
            media_id: card.id,
            promo_title: card.title,
            promo_subtitle: card.subtitle,
            redirection_ressource: card.externalUrl !== null ? "external" : "page",
          });
          if (card.deeplink !== undefined) {
            if (card.deeplink === "/kids") {
              openKids();
            } else {
              navigationStack.pushPage(new GenericPage(card.deeplink));
            }
          }
          return true;
        },
      },
      list => {
        this._carrouselTypes = this._modelSource$.value.map(el =>
          el.promoType === "LIVE" ? CarrouselTrackerType.LIVE : CarrouselTrackerType.DEFAULT
        );
        this._chipList = new CarrouselTrackerView(this._carrouselTypes);
        this.rootElement.appendChild(this._chipList.rootElement);
        if (this._carrouselTypes[0]) {
          this._focusTracker = new FocusTracker(list, "focusRectRed", "cardContainer");
        } else {
          this._focusTracker = new FocusTracker(list, "focusRect", "cardContainer");
        }

        list.focusedView$.didChange(
          (newView, oldView) => {
            const newTile = newView?.rootElement.querySelector(".cardContainer");
            const oldTile = oldView?.rootElement.querySelector(".cardContainer");
            newTile?.classList.add("fade");
            oldTile?.classList.remove("fade");
          },
          this,
          false
        );

        this.collectRelease(this._focusTracker.onRelease);
      }
    );

    this._list.focusedIndex$.didChange(
      index => {
        if (this._chipList && index !== undefined) {
          this._chipList.toggleActiveChip(index);
          if (this._carrouselTypes[index] === CarrouselTrackerType.LIVE) {
            this._focusTracker?.setFocusRectStyle("focusRectRed");
          } else {
            this._focusTracker?.setFocusRectStyle("focusRect");
          }
        }
        this.rootElement.classList.remove("videoPlaying");
        this._debouncePlayVideo();
      },
      this,
      true
    );

    // ____    __    ____  ___      .______      .__   __.  __  .__   __.   _______
    // \   \  /  \  /   / /   \     |   _  \     |  \ |  | |  | |  \ |  |  /  _____|
    //  \   \/    \/   / /  ^  \    |  |_)  |    |   \|  | |  | |   \|  | |  |  __
    //   \            / /  /_\  \   |      /     |  . `  | |  | |  . `  | |  | |_ |
    //    \    /\    / /  _____  \  |  |\  \----.|  |\   | |  | |  |\   | |  |__| |
    //     \__/  \__/ /__/     \__\ | _| `._____||__| \__| |__| |__| \__|  \______|
    //
    // playerVideo is a singleton and is state can change from another view.
    // Don't interact with player (play/pause) or other general component.
    // This warning is here because it created a bug on the player page when this view was loaded before opening it.
    playerVideo.state$.didChange(
      state => {
        if (!DS.DOMHelper.isInDOM(this.rootElement)) return;
        if (this._list.focused$.value === true) {
          switch (state) {
            case PlayerState.PLAYING:
              this.rootElement.classList.add("videoPlaying");
              break;
            case PlayerState.ENDED:
              this.rootElement.classList.remove("videoPlaying");
              if (this._modelSource$.value.length > 1) {
                this._nextSlide();
              } else {
                this._player?.onRelease();
              }
              break;
            case PlayerState.IDLE:
              this.rootElement.classList.remove("videoPlaying");
              break;
          }
        }
      },
      this,
      false
    );
  }

  private _debouncePlayVideo() {
    clearTimeout(this._playVideoTimeout);
    if (this._isFocused && !(navigationStack.topPage instanceof PlayerPage)) {
      this._playVideoTimeout = window.setTimeout(() => {
        if (this._isFocused === true) {
          void this._playVideo();
        }
        clearTimeout(this._playVideoTimeout);
      }, 2000);
    }
  }
  private async _playVideo() {
    const promoBox = this._list.modelFromIndex(this._list.focusedIndex$.value ?? 0);
    if (promoBox === undefined || promoBox.mediaId === null || playerAudio.state$.value === PlayerState.PLAYING) {
      // in case there is no media atached - move to the next slide after 10 seconds
      if (promoBox !== undefined && promoBox.mediaId === null) {
        this._nextPromoTimeout = window.setTimeout(() => {
          this._nextSlide();
          clearTimeout(this._nextPromoTimeout);
        }, 10000);
      }

      return;
    }
    const mediaId = promoBox.mediaId ?? "";
    if (this._player === undefined) {
      this._player = new TrailerPlayer(this.id, true);
      this.rootElement.appendChild(this._player.rootElement);
    }
    await this._player.load(mediaId);
    this._player.play();
    this.rootElement.classList.add("videoPlaying");
  }

  /**
   * Fetch PromoBox List if contentPath is defined
   * @param {Widget} widget The widget
   * @returns {Promise<PromoBoxCard[]>} A promise returning an array of PromoBoxCards
   */
  private async _content(widget: Widget): Promise<PromoBoxCard[]> {
    try {
      return widget.contentPath !== undefined
        ? (await APIAuvio.widget(widget.contentPath, WidgetPromoBox)).content
        : [];
    } catch (error) {
      Log.app.error("Error while fetching and parsing ", error);
      return [];
    }
  }

  private _nextSlide(scrollIndex?: number) {
    if (this._modelSource$.value !== undefined) {
      if (scrollIndex !== undefined) {
        this._scrollIndex = scrollIndex;
      } else if ((this._list.focusedIndex$.value ?? -1) + 1 >= this._modelSource$.value.length) {
        this._scrollIndex = 0;
      } else {
        this._scrollIndex = (this._list.focusedIndex$.value ?? -1) + 1;
      }
      void this._list.setFocusOnIndex(this._scrollIndex, {
        focus: false,
        animate: true,
        scroll: true,
      });
    }
  }

  private _startAutoSlide() {
    if (this._checkTimer !== undefined) return;
    this._checkTimer = window.setInterval(() => {
      this._nextSlide();
    }, this._scrollDuration * 1000);
  }

  private _stopAutoSlide() {
    window.clearInterval(this._checkTimer);
    this._checkTimer = undefined;
  }

  onNav(key: DS.Keys) {
    if (this._player !== undefined) {
      switch (key) {
        case DS.Keys.play:
          this._player.play();
          return true;
        case DS.Keys.pause:
          this._player.pause();
          return true;
        case DS.Keys.playPause:
          this._player.isPlaying() ? this._player.pause() : this._player.play();
          return true;
      }
    }
    return false;
  }

  onMouseDown(point: IPoint, target: HTMLElement) {
    const index = this._chipList?.indexFromElement(target);
    if (index !== undefined && index > -1) {
      this._nextSlide(index);
    }
  }

  onFocused() {
    this._isFocused = true;
    this._stopAutoSlide();
    this._debouncePlayVideo();
    if (this._destroyPlayerTimeout !== undefined) {
      clearTimeout(this._destroyPlayerTimeout);
      this._destroyPlayerTimeout = undefined;
    }
  }

  onUnfocused() {
    this._isFocused = false;
    this._startAutoSlide();
    this.rootElement.classList.remove("videoPlaying");
    // Waiting until the end of the exit animation to destroy video player
    this._destroyPlayerTimeout = window.setTimeout(() => {
      void playerVideo.destroy();
      this._destroyPlayerTimeout = undefined;
    }, 100);
  }

  onHidden() {
    // stop autoslide when the view is not display
    this._stopAutoSlide();
    // we need to clear the timeout because it will try to load a video but the player div is no more in the dom, the player.attach() will failed
    clearTimeout(this._playVideoTimeout);
    clearTimeout(this._nextPromoTimeout);
    if (this._isVisible === true) {
      this._player?.onRelease();
      this.rootElement.classList.remove("videoPlaying");
      this._isVisible = false;
    }
  }

  onShown() {
    this._isVisible = true;
    if (this._isFocused === false) {
      this._startAutoSlide();
    }
  }

  onRelease() {
    super.onRelease();
    clearTimeout(this._playVideoTimeout);
    clearTimeout(this._nextPromoTimeout);
    this._stopAutoSlide();
  }
}
