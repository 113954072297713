import "./mediaTileView.scss";

import { AudioIconView } from "~/components/views/audioIcon/audioIconView";
import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { ProgressBarView } from "~/components/views/common/progressBar/progressBarView";
import { DS } from "~/libs";
import { PlayHistoryHelper } from "~/tools/playHistoryHelper";

import { getAvailableDaysText, getFormatTime, getRemainingDays } from "../../../tools/time";
import { RTBF } from "../../../utils/rtbf";
import { CsaRatingView } from "../../views/csaView/csaRatingView";
import { FavorisView } from "../../views/favorisView/favorisView";
import { StampView } from "../../views/stampView/stampView";

export class MediaTileView extends AcceptsMouseFocusView {
  private _progressBarView: ProgressBarView | undefined;

  constructor(media: RTBF.MediaCard, widgetSize: "mosaicSize" | "listSize") {
    super(media.id, `mediaTileView ${widgetSize}`);

    const cardContainerElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "cardContainer",
    });
    const tileElt = DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainerElt,
      className: "cover",
      style: {
        backgroundImage: `url(${media.illustration?.s})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainerElt,
      className: "title",
      innerText: media.title,
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainerElt,
      className: "subtitle",
      innerText: media.subtitle,
    });
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: cardContainerElt,
      className: "category",
      innerText: media.categoryLabel,
    });

    if (media.rating !== undefined) {
      new CsaRatingView(media.rating, tileElt);
      this.rootElement.classList.add("hasRating");
    }
    if (media.stamp !== undefined) tileElt.appendChild(new StampView(media.stamp).rootElement);
    if (media.type === "AUDIO") {
      cardContainerElt.appendChild(new AudioIconView().rootElement);
    }

    if (media.publishedTo != null && getRemainingDays(new Date(media.publishedTo), new Date()) <= 7) {
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: cardContainerElt,
        className: "expired",
        innerText: getAvailableDaysText(new Date(media.publishedTo)),
      });
    } else if (media.releaseDate !== undefined) {
      const bottomInfo = DS.DOMHelper.createElement({
        tagName: "div",
        parent: cardContainerElt,
        className: "bottomInfo",
      });
      DS.DOMHelper.createElement({
        tagName: "span",
        parent: bottomInfo,
        innerText: media.releaseDate ?? "",
      });
      DS.DOMHelper.createElement({
        tagName: "span",
        parent: bottomInfo,
        innerText: getFormatTime(media.duration),
      });
    }

    tileElt.appendChild(new FavorisView(media.id).rootElement);
    const currentOffset$ = PlayHistoryHelper.getCurrentOffset(media.id);
    currentOffset$.didChange(
      currentOffset => {
        if (currentOffset === 0) return;
        if (this._progressBarView === undefined) {
          this._progressBarView = new ProgressBarView("media", media.duration);
          cardContainerElt.appendChild(this._progressBarView.rootElement);
        }
        this._progressBarView.updateCurrenOffset(currentOffset);
      },
      this,
      true
    );
  }
}
