import "./modifyLevelPage.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { APIGigyaOIDC } from "~/datas/api/apiGigyaOIDC";
import { DS } from "~/libs";
import { ParentalControlHelper } from "~/tools/parentalControlHelper";

import { ModifyLevelParentalButtonView } from "../../../components/views/parental/parentalButtonView";
import { WidgetView } from "../../../components/widgets/widgetView";
import { Config } from "../../../config";
import { GIGYA } from "../../../utils/gigya";

export class ModifyLevelPageView extends AcceptsMouseFocusView implements DS.IPage {
  private _list: DS.IListComponent<"widget" | "button", ModifyLevelWidgetView | ModifyLevelParentalButtonView>;
  private _newParentalControl$: DS.Listenable<GIGYA.ParentalControlLevel>;
  constructor() {
    super("ModifyLevelView", "modifyLevelView");

    // FIXIT: C'est déguelasse
    this._newParentalControl$ = new DS.Listenable(ParentalControlHelper.currentParentalControl());

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "modifyLevelTitle",
      innerText: t("parentalControl.modifyLevel.pageTitle"),
    });

    this.delegate = this._list = DS.createListComponent(
      {
        id: "modifyLevelWidget",
        className: "modifyLevelWidgetList",
        modelSource$: new DS.ModelSource(["widget", "button"]),
        viewFactory: item => {
          switch (item) {
            case "widget":
              return new ModifyLevelWidgetView(this._newParentalControl$);
            case "button":
              return new ModifyLevelParentalButtonView(this._newParentalControl$);
          }
        },
        scrollingMode: { type: DS.ScrollingType.page, horizontal: false },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport,
        onSelect: item => {
          const view = this._list.viewFromId(item);
          if (view instanceof ModifyLevelParentalButtonView) {
            void view.action();
          }
          return true;
        },
      },
      list => {
        void list.setFocusOnIndex(0);
      }
    );
  }

  isPopup = () => true;
}
class ModifyLevelItemWidgetView extends AcceptsMouseFocusView {
  private _selected$: DS.Listenable<boolean>;
  private _id: string;
  private _csa?: HTMLImageElement;
  constructor(id: string, selected = false) {
    super(id, "modifyLevelItemWidgetView");
    this._selected$ = new DS.Listenable<boolean>(selected);
    this._id = id;
    const filterItem = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "filterMenuItem",
    });
    const radioButtonOffIcon = require("@assets/images/radioButton/notSelected.png");
    const radioButtonOnIcon = require("@assets/images/radioButton/selected.png");
    const radiobutton = DS.DOMHelper.createElement({
      tagName: "span",
      parent: filterItem,
      className: "radioButton",
    });
    const radiobuttonIcon = DS.DOMHelper.createElement({
      tagName: "img",
      parent: radiobutton,
      className: "radioButtonIcon",
    });
    radiobuttonIcon.src = selected ? radioButtonOnIcon : radioButtonOffIcon;

    if (id === "all") {
      DS.DOMHelper.createElement({
        tagName: "span",
        parent: filterItem,
        className: "labelRadioButton",
        innerText: t("parentalControl.modifyLevel.deactivated"),
      });
    } else {
      const label = DS.DOMHelper.createElement({
        tagName: "span",
        parent: filterItem,
        className: "labelRadioButton",
      });
      DS.DOMHelper.createElement({
        tagName: "span",
        parent: label,
        className: "text",
        innerText: t("parentalControl.modifyLevel.limitedTo"),
      });
      this._csa = DS.DOMHelper.createElement({
        tagName: "img",
        parent: label,
        className: "textLogo",
      });
      this._csa.src = require("@assets/images/csa/csa" + id + ".png");
      DS.DOMHelper.createElement({
        tagName: "span",
        parent: label,
        className: "text",
        innerText: t("parentalControl.modifyLevel.yearsOld"),
      });
    }

    if (this._selected$.value) this.rootElement.classList.add("selected");

    this._selected$.didChange(value => {
      radiobuttonIcon.src = value ? radioButtonOnIcon : radioButtonOffIcon;
      if (value) this.rootElement.classList.add("selected");
      else this.rootElement.classList.remove("selected");
    }, this);
  }

  onSelect() {
    this._selected$.value = !this._selected$.value;
    return true;
  }

  getId() {
    return this._id;
  }

  onFocused() {
    if (this._csa) this._csa.src = require(`@assets/images/csa/csa${this._id}-black.png`);
  }

  onUnfocused() {
    if (this._csa) this._csa.src = require(`@assets/images/csa/csa${this._id}.png`);
  }
}

class ModifyLevelWidgetView extends WidgetView {
  private _descriptionContainer: HTMLElement;
  private _list: DS.IListComponent<GIGYA.ParentalControlLevel, ModifyLevelItemWidgetView>;
  constructor(newParentalControl$: DS.Listenable<GIGYA.ParentalControlLevel>) {
    super("ModifyLevelWidgetView", "modifyLevelWidgetView");

    let currentSelectedId: GIGYA.ParentalControlLevel = ParentalControlHelper.currentParentalControl();

    this._descriptionContainer = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "modifyLevelWidgetTitle",
      innerText: t("parentalControl.modifyLevel.deactivated_description"),
    });

    this.setDescription(ParentalControlHelper.currentParentalControl());

    this.delegate = this._list = DS.createListComponent({
      id: "modifyLevelWidget",
      className: "modifyLevelWidget",
      modelSource$: new DS.ModelSource([
        GIGYA.ParentalControlLevel.all,
        GIGYA.ParentalControlLevel.sixteen,
        GIGYA.ParentalControlLevel.twelve,
      ]),
      viewFactory: item => {
        if (item === APIGigyaOIDC.userInfo$.value?.data?.parentalControl)
          return new ModifyLevelItemWidgetView(item, true);
        return new ModifyLevelItemWidgetView(item);
      },
      scrollingMode: { type: DS.ScrollingType.page, horizontal: false },
      scrollDuration: Config.scrollDuration,
      mouseSupport: Config.mouseSupport,
      onSelect: item => {
        (this._list.viewFromId(item) as ModifyLevelItemWidgetView).onSelect();
        (this._list.viewFromId(currentSelectedId) as ModifyLevelItemWidgetView).onSelect();
        this.setDescription(item);
        currentSelectedId = item as GIGYA.ParentalControlLevel;
        newParentalControl$.value = currentSelectedId;
        return true;
      },
    });
  }

  setDescription(idLevel: GIGYA.ParentalControlLevel) {
    if (idLevel === GIGYA.ParentalControlLevel.all) {
      this._descriptionContainer.innerText = t("parentalControl.modifyLevel.deactivated_description");
    } else {
      this._descriptionContainer.innerHTML = "";
      DS.DOMHelper.createElement({
        tagName: "span",
        parent: this._descriptionContainer,
        className: "modifyLevelDescText",
        innerText: t("parentalControl.modifyLevel.description"),
      });

      const PCLevels = Object.values(GIGYA.ParentalControlLevel).slice(
        Object.values(GIGYA.ParentalControlLevel).indexOf(idLevel),
        -1
      );
      PCLevels.forEach((level, index) => {
        try {
          DS.DOMHelper.createElement({
            tagName: "img",
            parent: this._descriptionContainer,
            className: "modifyLevelDescLogo",
          }).src = require("@assets/images/csa/csa" + level + "-orange.png");
        } catch (error: unknown) {
          Log.app.error("Can't find the csa with: ", level);
        }

        if (index < PCLevels.length - 1) {
          DS.DOMHelper.createElement({
            tagName: "span",
            parent: this._descriptionContainer,
            className: "modifyLevelDescText icons",
            innerText: t("parentalControl.modifyLevel.and"),
          });
        }
      });

      DS.DOMHelper.createElement({
        tagName: "span",
        parent: this._descriptionContainer,
        className: "modifyLevelDescText icons yellow",
        innerText: t("parentalControl.modifyLevel.yearsOld"),
      });
    }
  }
}
