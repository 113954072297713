import "./mediaPremiumTileView.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { DS } from "~/libs";
import { PremiumHelper } from "~/tools/premiumHelper";
import { MediaPremiumCard } from "~/utils/rtbf/export";

import { CsaRatingView } from "../../views/csaView/csaRatingView";
import { FavorisView } from "../../views/favorisView/favorisView";
import { LockView } from "../../views/lockView/lockView";
import { StampView } from "../../views/stampView/stampView";

export class MediaPremiumTileView extends AcceptsMouseFocusView {
  constructor(mediaPremium: MediaPremiumCard, widgetSize: "mosaicSize" | "listSize") {
    super(mediaPremium.id, `mediaPremiumTileView ${widgetSize}`);
    const tileElement = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "tileCover cardContainer",
    });
    if (mediaPremium.illustration?.s !== undefined) {
      tileElement.style.backgroundImage = `url(${mediaPremium.illustration.s})`;
    } else {
      DS.DOMHelper.createElement({
        tagName: "div",
        className: "tileTitle",
        parent: tileElement,
        innerText: mediaPremium.title,
      });
    }

    if (mediaPremium.rating !== undefined) {
      new CsaRatingView(mediaPremium.rating, tileElement);
    }

    if (mediaPremium.stamp !== undefined) {
      tileElement.appendChild(new StampView(mediaPremium.stamp).rootElement);
    }

    if (PremiumHelper.isPurchased(mediaPremium.products) === false) new LockView(tileElement);

    tileElement.appendChild(new FavorisView(mediaPremium.id).rootElement);
  }
}
