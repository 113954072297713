import mux from "mux-embed";
import { z } from "zod";

import { PlayerEvents } from "~/analytics/constants";
import { PlayerCoreConnector } from "~/analytics/PlayerCoreConnector";
import { APIRedbee, Config } from "~/datas";
import { DS } from "~/libs";
import { HandledPlayerErrors } from "~/pages/player/playerPage";
import { PlayerCommon } from "~/player/playerCommon";
import { HTML5ErrorEvent, HTML5PlayerEvent, ShakaPlayerEvent } from "~/player/shaka/definitions";
import { getTimelineSpriteCues, ISpriteCue } from "~/player/spriteVTTParser";
import { DevicePreferenceHelper } from "~/tools/devicePreferencesManager";
import { FastTVProgramService } from "~/tools/fastTVProgramService";
import {
  IPlayer,
  IPlayerLoadOptions,
  IPlayerQuality,
  IPlayerTrack,
  PlayerError,
  PlayerState,
  PlayerStreamType,
} from "~/tools/player";
import { GemiusPlayerEvent, TrackingHelper } from "~/tools/trackingHelper";
import { areAllPartnersAccepted } from "~/utils/didomi/didomi";
import { StatusError } from "~/utils/errors";
import { SpritesPlayResponse } from "~/utils/redbee/models";
import { EmbedType } from "~/utils/rtbf/models";

import { APIGigyaOIDC } from "../../datas/api/apiGigyaOIDC";
import { Listenable } from "../../libs/exports";
import { RedBeePlayerEvents } from "../redbee/definitions";

/**
 * This is the implementation of the IPlayer interface.
 * When you add a function here, please do not forget to add it
 * in the interface.
 * This allow to expose to developpers only functions members
 * that they can trust.
 */
class Player extends PlayerCommon implements IPlayer {
  video?: shaka.Player;

  uiVisible$ = new Listenable<boolean>(true);
  currentPlayerElt: (HTMLMediaElement & { mux?: IMuxEmbedInstancePlugin }) | null | undefined = null;
  playerInitTime = 0;
  protected playerCoreConnector: PlayerCoreConnector | undefined;
  private _source = "";
  private _maxAttempts = 3;
  private _attemptsCount: Record<string, number> = {};

  private _spriteBackendResponse: SpritesPlayResponse;
  private readonly _spriteCueCache: { [P: string]: ISpriteCue[] };

  constructor() {
    super();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    shaka.log?.setLevel(shaka.log.Level.DEBUG);
    shaka.polyfill.installAll();

    this._spriteBackendResponse = [];
    this._spriteCueCache = {};
  }

  private _eventError(event: HTML5ErrorEvent) {
    //const eventName = Object.keys(PlayerEvents).find(key => PlayerEvents[key as keyof typeof PlayerEvents] === event);
    Log.player.error(`[Shaka Player] Error ${event}:`);
  }

  private _eventSetState(state: HTML5PlayerEvent) {
    Log.player.warn("event ", state);
    switch (state) {
      case "loadstart":
        this.state$.value = PlayerState.LOADING;
        break;
      case "loadedmetadata":
        {
          this.state$.value = PlayerState.LOADING;
          if (this._options.audioOnly !== true) {
            // set audio with user's preference by defaut
            const audio = this.getPreferedAudioTrack();
            if (audio !== undefined) {
              this.audioTrack = audio;
            } else {
              Log.player.warn("Can't set up the audio from device preference helper.");
              this.video?.selectAudioLanguage(this.video.getAudioLanguages()[0]);
            }

            // set subtitle with user's preference by defaut
            const subtitle = this.getPreferedTextTrack();
            if (subtitle !== undefined) {
              this.subtitleTrack = subtitle;
            } else {
              Log.player.warn("Can't set up the subtitle from device preference helper.");
              this.subtitleTrack = this.subtitleTracks()[0];
            }
          }
        }
        break;
      case "playing":
        if (this.state$.value !== PlayerState.PLAYING) {
          this.trackAnalytics("play", {
            boolean_value: true,
          });
        }
        this.state$.value = PlayerState.PLAYING;
        this.trackProgramEvent(GemiusPlayerEvent.play);
        this.showSpinner$.value = false;
        if (
          this.type$.value === PlayerStreamType.VIDEO_LIVE ||
          this.type$.value === PlayerStreamType.VIDEO_LIVE_AD_REPLACEMENT ||
          this.type$.value === PlayerStreamType.VIDEO_SHIFT ||
          this.type$.value === PlayerStreamType.VIDEO_SHIFT_AD_REPLACEMENT
        ) {
          this.seekTime$.value = undefined;
        }
        if (this.restarting && this.type$.value !== undefined) {
          const player = this.currentPlayerElt;
          if (player) {
            player.pause();
            if (this.video && this.video.isLive()) {
              player.currentTime = (this.asset$.value?.resource.live?.start.getTime() ?? 0) / 1000;
            } else {
              player.currentTime = 0;
            }
            this.showControls$.value = true;
            this._showSpiner(300);
            setTimeout(() => {
              void player.play();
            }, 500);
          }
          this.updateType();
          this.restarting = false;
        }
        break;
      case "timeupdate":
        this.showSpinner$.value = false;
        break;
      case "pause":
        this.state$.value = PlayerState.PAUSED;
        this.trackAnalytics("pause", {
          boolean_value: true,
        });
        break;
      case "waiting":
        this.state$.value = PlayerState.BUFFERING;
        this.trackProgramEvent(GemiusPlayerEvent.buffer);
        break;
      case "seeking":
        this._showSpiner(300);
        this.trackAnalytics("seek", {
          integer_value: this.seekTime$.value,
        });
        break;
      case "ended":
        this.state$.value = PlayerState.ENDED;
        this.trackProgramEvent(GemiusPlayerEvent.complete);
        this.trackAnalytics("ended");
        break;
      case "error":
        this.state$.value = PlayerState.ERROR;
        break;
      default:
        this.state$.value = PlayerState.IDLE;
    }
  }

  private _analyticsEventsListener: Partial<Record<string, (event: Event) => void>> = {
    [ShakaPlayerEvent.LOADING]: this._analyticsEventSetState.bind(this),
    [ShakaPlayerEvent.DRMSESSIONUPDATE]: this._analyticsEventSetState.bind(this),
    [ShakaPlayerEvent.BUFFERING]: this._analyticsEventSetState.bind(this),
    [ShakaPlayerEvent.LOADED]: this._analyticsEventSetState.bind(this),
    [ShakaPlayerEvent.MEDIAQUALITYCHANGED]: this._analyticsEventSetState.bind(this),
    [ShakaPlayerEvent.ERROR]: this._analyticsEventSetState.bind(this),
  };

  private _analyticsEventSetState(event: Event & Partial<{ buffering: boolean; code: number; message: string }>) {
    if (!this.playerCoreConnector) {
      return;
    }

    switch (event.type) {
      case ShakaPlayerEvent.LOADING:
        this.playerCoreConnector.triggerCustomEvents(PlayerEvents.LOADING);
        break;
      case ShakaPlayerEvent.DRMSESSIONUPDATE:
        this.playerCoreConnector.triggerCustomEvents(PlayerEvents.DRM_UPDATE, { type: event.type });
        break;
      case ShakaPlayerEvent.BUFFERING:
        this.playerCoreConnector.triggerCustomEvents(
          event.buffering === true ? PlayerEvents.BUFFERING : PlayerEvents.BUFFERED,
          { currentTime: this.currentPlayerElt?.currentTime }
        );
        break;
      case ShakaPlayerEvent.LOADED:
        this.playerCoreConnector.triggerCustomEvents(PlayerEvents.LOADED);
        break;
      case ShakaPlayerEvent.MEDIAQUALITYCHANGED:
        this.playerCoreConnector.triggerCustomEvents(PlayerEvents.BITRATE_CHANGED, {
          currentTime: this.currentPlayerElt?.currentTime,
          bitrate: this.video?.getStats().estimatedBandwidth,
        });
        break;
      case HTML5PlayerEvent.PLAYING: {
        this.state$.value !== PlayerState.PAUSED &&
          this.playerCoreConnector.triggerCustomEvents(PlayerEvents.START, {
            bitrate: this.video?.getStats().estimatedBandwidth,
            duration: this.currentPlayerElt?.duration,
            source: this._source,
          });
        break;
      }
      case HTML5PlayerEvent.PAUSE:
        this.playerCoreConnector.triggerCustomEvents(PlayerEvents.PAUSE, {
          currentTime: this.currentPlayerElt?.currentTime,
        });
        break;
      case HTML5PlayerEvent.SEEKED:
        this.playerCoreConnector.triggerCustomEvents(PlayerEvents.SEEKED, {
          currentTime: this.currentPlayerElt?.currentTime,
        });
        break;
      case HTML5PlayerEvent.PLAY:
        this.state$.value === PlayerState.PAUSED &&
          this.playerCoreConnector.triggerCustomEvents(PlayerEvents.RESUME, {
            currentTime: this.currentPlayerElt?.currentTime,
          });
        break;
      case HTML5PlayerEvent.ENDED:
        this.playerCoreConnector.triggerCustomEvents(PlayerEvents.ENDED, {
          currentTime: this.currentPlayerElt?.currentTime,
        });
        break;
      case HTML5PlayerEvent.ERROR:
        this.playerCoreConnector.triggerCustomEvents(PlayerEvents.ERROR, {
          // error: event.error,
          // originalError: event.originalError,
          code: event.code,
          message: event.message,
        });
        break;
    }
  }

  private _registerEvents: {
    (video: shaka.Player, destroy: true): Promise<void>;
    (
      video: shaka.Player,
      destroy: false,
      retryData: { assetID: string; embedType: EmbedType; options?: IPlayerLoadOptions }
    ): Promise<void>;
  } = async (
    video: shaka.Player,
    destroy: boolean,
    retryData?: {
      assetID: string;
      embedType: EmbedType;
      options?: IPlayerLoadOptions;
    }
  ) => {
    if (destroy) {
      for (const eventName in this._analyticsEventsListener) {
        const listener = this._analyticsEventsListener[eventName];
        listener && video.removeEventListener(eventName, listener);
      }
      await video.destroy();
    } else {
      const player = this.currentPlayerElt;
      if (!player) {
        throw new PlayerError(1, "Player - no player element");
      }
      player.onloadstart = () => {
        this._eventSetState(HTML5PlayerEvent.LOADSTART);
      };
      player.onloadedmetadata = () => {
        this._eventSetState(HTML5PlayerEvent.LOADMETADATA);
      };
      player.oncanplay = () => {
        this._eventSetState(HTML5PlayerEvent.CANPLAY);
      };
      player.onplaying = ev => {
        this._analyticsEventSetState(ev);
        this._eventSetState(HTML5PlayerEvent.PLAYING);
      };
      player.onplay = ev => {
        this._analyticsEventSetState(ev);
      };
      player.onwaiting = () => {
        this._eventSetState(HTML5PlayerEvent.WAITING);
      };
      player.ontimeupdate = () => {
        this.eventTimeUpdate({
          currentTime: player.currentTime,
          duration: video.isLive() ? video.seekRange().end : player.duration,
          seekable: video.seekRange(),
          audioTrack: this.audioTrack,
          subtitleTrack: this.subtitleTrack,
        });
        // Workaround to stop manually the trailers because when the Shaka option disableAudio is set the event ended will not to launch
        if (
          !(retryData?.options?.isPromoBoxTrailer ?? false) &&
          this._options.muted === true &&
          DS.platform.type === DS.PlatformType.ps4 &&
          this.video
        ) {
          if (this.percentRatio$.value >= 99) {
            // Was 97% before. Maybe when the trailer is super fast (less than 15 seconds) this could be crucial and skip the if statement but usually the trailers are long enough
            if (this.currentPlayerElt) {
              this.currentPlayerElt.pause();
              this.currentPlayerElt.currentTime = 0;
            }
            this._eventSetState(HTML5PlayerEvent.ENDED);
          }
        }
      };
      player.onseeking = () => {
        this._eventSetState(HTML5PlayerEvent.SEEKING);
      };
      player.onseeked = ev => {
        this._analyticsEventSetState(ev);
      };
      player.onpause = ev => {
        this._eventSetState(HTML5PlayerEvent.PAUSE);
        this._analyticsEventSetState(ev);
      };
      player.onended = ev => {
        this._eventSetState(HTML5PlayerEvent.ENDED);
        this._analyticsEventSetState(ev);
      };
      player.onerror = ev => {
        if (this.currentPlayerElt) {
          this.currentPlayerElt.src = "";
        }
        this._eventError({ target: player });
        this._analyticsEventSetState(ev instanceof Event ? ev : ({} as Event));
      };

      player.textTracks.onchange = () => {
        const track = this._getActiveTextTrack(player.textTracks);
        if (track) {
          track.oncuechange = this._changeSubtitlesPosition.bind(this);
        }
      };
      for (const eventName in this._analyticsEventsListener) {
        const listener = this._analyticsEventsListener[eventName];
        listener && video.addEventListener(eventName, listener);
      }
      player.addEventListener(RedBeePlayerEvents.PROGRAM_CHANGED, (data: unknown) => {
        try {
          const parser = z.object({ detail: z.unknown() });
          this._handleProgramChanged(parser.parse(data).detail);
        } catch (e) {
          Log.player.error("Parsing error from fastTVProgramService error", e, data);
        }
      });

      const nwEngine = video.getNetworkingEngine();
      const vodManifestNotFoundHandler = async (event: Event & { error?: shaka.util.Error }) => {
        const code = event.error?.code;
        const data = event.error?.data;
        switch (code) {
          case shaka.util.Error.Code.HTTP_ERROR:
            if (Array.isArray(data) && typeof data[0] === "string") {
              if (!this._attemptsCount[data[0]]) this._attemptsCount[data[0]] = 0;
              this._attemptsCount[data[0]]++;
              if (this._attemptsCount[data[0]] >= this._maxAttempts && retryData) {
                await this.destroy();
                this._loadError({}, true, async () => {
                  await this.load(retryData.assetID, retryData.embedType, retryData.options);
                });
              }
            }
            break;

          case shaka.util.Error.Code.BAD_HTTP_STATUS:
            if (
              // each type of error has its own data structure (or none at all), tread with care
              Array.isArray(data) &&
              typeof data[0] === "string" &&
              data[1] === 404 &&
              data[4] === shaka.net.NetworkingEngine.RequestType.MANIFEST
            ) {
              if (!this._attemptsCount[data[0]]) this._attemptsCount[data[0]] = 0;
              this._attemptsCount[data[0]]++;
              if (this._attemptsCount[data[0]] >= this._maxAttempts && retryData) {
                await this.destroy();
                this._loadError({}, true, async () => {
                  await this.load(retryData.assetID, retryData.embedType, retryData.options);
                });
              }
            }
            break;
        }
      };

      nwEngine?.addEventListener("retry", vodManifestNotFoundHandler);
      video.addEventListener("load", () => {
        nwEngine?.removeEventListener("retry", vodManifestNotFoundHandler);
      });
    }
  };

  attach(containerElement: HTMLElement, audioOnly = false) {
    const tagName = audioOnly ? "audio" : "video";
    this.currentPlayerElt =
      containerElement.querySelector(tagName) ||
      DS.DOMHelper.createElement({
        tagName: tagName,
        parent: containerElement,
        id: "PlayerShaka",
      });
  }

  async destroy() {
    if (await super.destroy()) {
      if (this.playerCoreConnector) {
        this.playerCoreConnector.triggerCustomEvents(PlayerEvents.STOP, {
          currentTime: this.currentPlayerElt?.currentTime,
        });
        this.playerCoreConnector.destroy();
        this.playerCoreConnector = undefined;
      }

      if (this.video) {
        await this._registerEvents(this.video, true);
        return true;
      }
    }
    return false;
  }

  async load(assetID: string, embedType: EmbedType, options?: IPlayerLoadOptions) {
    this._attemptsCount = {};

    // Check options
    const catchError = options?.catchError ?? true;
    const isRadioLive = options?.isRadioLive ?? false;
    const isTrailer = options?.isTrailer ?? false;
    // Make sure that no player is currently playing
    await this.destroy();

    this.playerInitTime = mux.utils.now();

    this._showSpiner();

    // Get token redbee
    await this.getToken();

    // Load the asset to get the assetID
    try {
      await this.loadAsset(assetID, embedType, isTrailer, isRadioLive);
    } catch (e) {
      // If it's an unconnected error, let it propagate
      if (e instanceof StatusError && e.statusCode in HandledPlayerErrors === true) throw e;
      return this._loadError({}, true, async () => {
        await this.load(assetID, embedType, options);
      });
    }

    // Disable analytics if it's a trailer player
    this.analytics = !isTrailer;

    // Init the player
    if (this.currentPlayerElt) {
      this.video = new shaka.Player();
      await this.video.attach(this.currentPlayerElt);
    } else {
      throw new PlayerError(1, "Player - no player element");
    }

    const radio = await this.getRadioData(assetID, isRadioLive);

    // Get the AssetId to use to launch the playback
    const assetId = this.getAssetId(assetID, radio, isRadioLive, isTrailer);

    // Return the load status
    try {
      this._options.assetId = assetId;
      const playData = await APIRedbee.getPlayData(this._options, this.ads);

      /*
       * Use case : when we click on the replay button on a live content view (after it's finished)
       * When it's live the manifest return timestamp values and when it's over it return seconds from the beginning
       * This test check if the playData response is a live
       * Then it tell the player to use the control + progress bar of a VOD instead of a live
       */
      if (this.isLive() === true && playData.streamInfo?.live === false) {
        this._updateCurrentLiveAsVod();
      }

      if (Array.isArray(playData.formats) && playData.formats.length) {
        const formatType = this._options.audioOnly === true ? "MP3" : "DASH";
        const format = playData.formats.find(f => f.format === formatType);
        if (format && format.mediaLocator != null) {
          const drmConfig: Partial<shaka.extern.DrmConfiguration> = {};
          // DRM
          if (format.drm) {
            drmConfig.servers = {
              "com.microsoft.playready": format.drm["com.microsoft.playready"]
                ? format.drm["com.microsoft.playready"].licenseServerUrl
                : "",
              "com.widevine.alpha": format.drm["com.widevine.alpha"]
                ? format.drm["com.widevine.alpha"].licenseServerUrl
                : "",
            };
            if (DS.platform.type === DS.PlatformType.ps5) {
              const persistent = true;
              drmConfig.advanced = {
                "com.microsoft.playready": {
                  distinctiveIdentifierRequired: true,
                  sessionType: persistent ? "persistent-license" : "temporary",
                  persistentStateRequired: persistent,
                  audioRobustness: "",
                  individualizationServer: "",
                  serverCertificate: new Uint8Array(0),
                  serverCertificateUri: "",
                  videoRobustness: "",
                },
              };
            }
            //drmConfig.logLicenseExchange = true;
          }
          // BOOKMARK
          const startTime = playData.bookmarks.lastViewedOffset;
          this.video.resetConfiguration();
          this.video.configure({
            drm: drmConfig,
            manifest: {
              disableAudio: this._options.muted ?? false,
              disableVideo: this._options.audioOnly ?? false,
            },
            streaming: {
              rebufferingGoal: 2,
              retryParameters: {
                maxAttempts: this._maxAttempts,
              },
            },
          });

          const time = startTime != null && options?.restart !== true ? startTime / 1000 : undefined;

          // Init RedBee Analytics
          if (
            this.analytics &&
            this._options.customer != null &&
            this._options.businessUnit != null &&
            this._options.sessionToken != null
          ) {
            this._source = format.mediaLocator;
            this.playerCoreConnector = new PlayerCoreConnector({
              customer: this._options.customer,
              businessUnit: this._options.businessUnit,
              exposureBaseUrl: this._options.exposureBaseUrl,
              analyticsBaseUrl: playData.analytics?.baseUrl,
              sessionToken: this._options.sessionToken,
              playerName: Config().REDBEE.appName,
              //playerVersion: Config().REDBEE.appName,
              debug: false, // when set to true, the request will not be sent
              device: {
                id: DS.platform.deviceId,
                appName: Config().REDBEE.appName,
                appType: "browser",
                manufacturer: "Sony",
                model: DS.platform.type === DS.PlatformType.ps4 ? "PLAYSTATION 4" : "PLAYSTATION 5",
              },
            });
            this.playerCoreConnector.connect(
              {
                playSessionId: playData.playSessionId,
                requestId: playData.requestId,
                cdn: playData.cdn,
                analytics: playData.analytics,
                playbackFormat: "DASH",
                assetId: this._options.assetId,
                autoplay: this._options.autoplay,
                startTime: time,
              },
              {
                name: "Shaka",
                version: shaka.Player.version.slice(1),
              },
              shaka.Player.isBrowserSupported()
            );
          }

          // For seek tooltip
          if (playData.sprites && playData.sprites.length) {
            this._spriteBackendResponse = playData.sprites;
          }

          // Events
          await this._registerEvents(this.video, false, { assetID, embedType, options });

          if (!isTrailer) {
            /* eslint-disable @typescript-eslint/naming-convention */
            mux.monitor(
              this.currentPlayerElt,
              TrackingHelper.muxData(
                {
                  video_stream_type: this.isLive() || isRadioLive ? "live" : "on-demand",
                  video_id: assetId,
                  video_duration: this.asset$.value?.resource.duration,
                  video_title: assetID + " - " + this.asset$.value?.resource.title,
                  video_series: this.asset$.value?.resource.embed?.program?.title ?? "",
                  video_source_url: format.mediaLocator,
                  viewer_user_id: areAllPartnersAccepted() ? APIGigyaOIDC.userInfo$.value?.uid ?? "" : "",
                  player_init_time: this.playerInitTime,
                  // sub_property_id: ['drm' | 'no_drm'], We are waiting for the info from the RAM API
                },
                {
                  name: "Shaka",
                  version: shaka.Player.version,
                }
              )
            );
          }

          await this.video.load(format.mediaLocator, time);
        } else {
          throw new PlayerError(1, "Player - no DASH data");
        }
      } else {
        throw new PlayerError(1, "Player - no format found");
      }
    } catch (error: unknown) {
      this._loadError(error, catchError, async () => {
        await this.load(assetID, embedType, options);
      });
      throw new PlayerError(1, "Player - bad asset");
    }

    await this.loadRadioLive(isRadioLive, radio);

    // Tracking
    await this.initTracking(this.currentPlayerElt);

    if (this._options.autoplay === true) {
      void this.currentPlayerElt.play();
    }
  }

  private _showSpiner = (delay?: number) => {
    if (typeof delay === "number") {
      setTimeout(() => {
        if (this.video && this.video.isBuffering() && !this.isPlaying()) {
          this.showSpinner$.value = true;
        }
      }, 300);
    } else {
      this.showSpinner$.value = true;
    }
  };

  play(firstStart = false, restart = false) {
    if (!this.currentPlayerElt) {
      return;
    }
    this.restarting = restart;
    if (restart) {
      DS.platform.type !== DS.PlatformType.ps4 && this.currentPlayerElt.pause();

      this.currentPlayerElt.currentTime = 0;
      this.showControls$.value = true;
      this._showSpiner(300);
    }
    if (typeof this.seekTime$.value === "number") {
      this.currentTime$.value = this.seekTime$.value;
      this.seekTime$.value = undefined;
      this.currentPlayerElt.currentTime = this.currentTime$.value;
      this._showSpiner(300);
      this.hideTooltip();

      // Gemius don't want seek event to be send on startup so we need to send the hit in specific occasion
      this.trackProgramEvent(GemiusPlayerEvent.seek);
    }
    super.play();
    void this.currentPlayerElt.play();
  }

  pause(isUserAction = false) {
    if (this.asset$.value?.isFastTV === true) return;
    this.updateType();

    super.pause(isUserAction);
    this.currentPlayerElt?.pause();
  }

  isPlaying() {
    return this.state$.value === PlayerState.PLAYING;
  }

  seekTo(playbackPos?: number) {
    if (this.currentPlayerElt && typeof playbackPos === "number") {
      this._showSpiner();
      this.currentPlayerElt.currentTime = playbackPos;
    }
  }

  forward(preview = false) {
    this.seek(this._TIME_INCREMENT_FW, preview);
    this._showSpiner();
    !preview && this.play();
    this.trackAnalytics("forward", {
      boolean_value: true,
    });
  }

  rewind(preview = false) {
    this.updateType();
    this.seek(this._TIME_INCREMENT_RW, preview);
    this._showSpiner();
    !preview && this.play();
    this.trackAnalytics("rewind", {
      boolean_value: true,
    });
  }

  get audioTrack() {
    const activeTrack = this.video?.getVariantTracks().find(track => track.active);
    return {
      id: "0",
      active: true,
      track: activeTrack
        ? {
            id: String(activeTrack.id),
            label: activeTrack.label !== null ? activeTrack.label : "",
            language: activeTrack.language,
            raw: { active: true, roles: activeTrack.roles },
          }
        : {
            id: "0",
            label: "Not Found",
            language: "Not Found",
            raw: { active: true, roles: [] },
          },
    };
  }

  set audioTrack(track: IPlayerTrack) {
    const targetTrack = this.video?.getVariantTracks().find(t => t.id === Number(track.track.id));
    if (targetTrack) {
      this.video?.selectVariantTrack(targetTrack, true);
      this.trackAnalytics("change_audio", {
        boolean_value: true,
        string_value: track.id,
      });
    }
  }

  audioTracks() {
    const selectedTrack = this.audioTrack;
    const labels: string[] = [];
    return [
      this.audioTrack,
      ...(
        this.video?.getVariantTracks().filter(function (item) {
          if (item.label != null && item.label !== selectedTrack.track.label && !labels.includes(item.label)) {
            labels.push(item.label);
            return true;
          } else {
            return false;
          }
        }) ?? []
      ).map((item, index) => {
        return {
          id: `${index + 1}`,
          active: false,
          track: {
            id: String(item.id),
            label: item.label !== null ? item.label : "",
            language: item.language,
            raw: { active: false, roles: item.roles },
          },
        };
      }),
    ];
  }

  get subtitleTrack() {
    const activeTrack = this.video?.getTextTracks().find(track => track.active);
    return {
      id: "0",
      active: true,
      track: activeTrack
        ? {
            id: String(activeTrack.id),
            label: activeTrack.label !== null ? activeTrack.label : "",
            language: activeTrack.language,
            raw: { active: true, roles: activeTrack.roles },
          }
        : {
            id: "DOTSCREEN_SUBTITLE_EMPTY",
            label: "DOTSCREEN_SUBTITLE_EMPTY",
            language: "Not Found",
            raw: { active: true, roles: [] },
          },
    };
  }

  set subtitleTrack(track: IPlayerTrack) {
    if (!this.video) {
      return;
    }

    if (track.track.id === "DOTSCREEN_SUBTITLE_EMPTY") {
      this.video.setTextTrackVisibility(false);
      return;
    }
    try {
      const targetTrack = this.video.getTextTracks().find(t => t.id === Number(track.track.id));
      if (targetTrack) {
        this.video.selectTextTrack(targetTrack);
        this.video.setTextTrackVisibility(true);
        this.trackAnalytics("change_subtitle", {
          string_value: track.id,
          boolean_value: true,
        });
      }
    } catch (error: unknown) {
      Log.player.error("Error when setting subtitles: ", error);
    }
  }

  subtitleTracks() {
    let subtitleEnable = false;

    const playerTrackArr: IPlayerTrack[] = [
      ...(this.video?.getTextTracks() ?? []).map((item, index) => {
        const active = item.active;
        const track = {
          id: `${index + 1}`,
          active,
          track: {
            id: String(item.id),
            label: item.label ?? (t("languageSubtitleSelection." + item.language.toUpperCase()) || ""),
            language: item.language,
            raw: { roles: item.roles, active },
          },
        };
        if (active === true) subtitleEnable = true;

        return track;
      }),
    ];

    const emptyTrack = {
      id: "0",
      active: !subtitleEnable,
      track: {
        id: "DOTSCREEN_SUBTITLE_EMPTY",
        label: "Aucun",
        language: "DISABLE",
        raw: { active: !subtitleEnable, roles: [] },
      },
    };
    playerTrackArr.unshift(emptyTrack);
    if (!subtitleEnable) this.subtitleTrack = emptyTrack;
    return playerTrackArr;
  }

  qualityLevels() {
    return undefined;
  }

  set quality(quality: IPlayerQuality) {
    // this.video?.setQualityLevel(quality);
    // this.trackAnalytics("change_quality", {
    //   integer_value: quality.width,
    // });
  }

  seekToLive() {
    if (this.video && this.currentPlayerElt) {
      if (!this.isPlaying()) {
        void this.currentPlayerElt.play();
      }
      this.video?.goToLive();
      this._showSpiner(300);
    }
  }

  applySubtitleStyle() {
    if (this.currentPlayerElt) {
      this.currentPlayerElt.classList.add(DevicePreferenceHelper.cachedPreferences().subtitlePersonnalization.size);
      this.currentPlayerElt.classList.add(DevicePreferenceHelper.cachedPreferences().subtitlePersonnalization.style);
    }
  }

  private _getActiveTextTrack(list: TextTrackList) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].mode === "showing") {
        return list[i];
      }
    }
  }

  private _changeSubtitlesPosition() {
    if (this.currentPlayerElt) {
      const isVisible = this.uiVisible$.value;
      const currentTrack = this._getActiveTextTrack(this.currentPlayerElt.textTracks);
      if (currentTrack) {
        const { size, position } = DevicePreferenceHelper.cachedPreferences().subtitlePersonnalization;
        const isTop = position === "TOP";
        type LineType = { [p in typeof size]: [number, number] };
        const linePS: LineType = {
          SMALL: isTop ? [2, 0] : [7, 20],
          MEDIUM: isTop ? [3, 0] : [8, 14],
          BIG: isTop ? [4, 1] : [10, 12],
        };
        const line: LineType = {
          SMALL: isTop ? [5, 0] : [16, -1],
          MEDIUM: isTop ? [4, 1] : [12, -1],
          BIG: isTop ? [3, 1] : [9, -1],
        };
        if (currentTrack.cues && currentTrack.cues.length > 0) {
          for (let i = 0; i < currentTrack.cues.length; i++) {
            const cue = currentTrack.cues[i];
            if (cue instanceof VTTCue && cue.align !== "center") {
              cue.align = "center";
            }
          }
        }
        const cues = currentTrack.activeCues;
        if (cues && cues.length) {
          const activeCue = cues[0];
          if (activeCue instanceof VTTCue && !activeCue.vertical) {
            const list = [DS.PlatformType.ps4, DS.PlatformType.ps5].includes(DS.platform.type)
              ? linePS[size]
              : line[size];
            if (activeCue.line === -1 || activeCue.line === list[!isVisible ? 0 : 1]) {
              activeCue.line = list[isVisible ? 0 : 1];
            }
          }
        }
      }
    }
  }

  positionSubtitles(playerControls?: HTMLElement) {
    if (playerControls !== undefined && this.currentPlayerElt) {
      this.uiVisible$.value = playerControls.style.visibility !== "hidden";
      this._changeSubtitlesPosition();
    }
  }

  async getTimelineSpriteCues(width = 160) {
    const sprites = this._spriteBackendResponse;
    if (Array.isArray(sprites) && sprites.length) {
      let offset = 0;
      let vtt: string | undefined;
      for (const sprite of sprites) {
        vtt = sprite.vtt;
        if (sprite.offsetInMs != null) {
          offset = sprite.offsetInMs / 1000;
        }
        if (sprite.vtt != null && width <= sprite.width) {
          break;
        }
      }
      if (vtt != null) {
        if (this._spriteCueCache[vtt] == null) {
          this._spriteCueCache[vtt] = await getTimelineSpriteCues(vtt, offset);
        }
        return this._spriteCueCache[vtt];
      }
    }
    return [];
  }

  getCurrentTime() {
    return this.currentTime$.value;
  }
}

export class PlayerVideoShaka extends Player {
  private _programService: FastTVProgramService | null = null;

  constructor() {
    super();
  }

  async load(assetID: string, embedType: EmbedType, options?: IPlayerLoadOptions) {
    await super.load(assetID, embedType, options);
    // Setup the garbage collector
    options?.collector?.collectRelease(() => {
      void this.destroy();
    });
    if (this.asset$.value?.isLive() === false) {
      this.type$.value = PlayerStreamType.VIDEO;
    } else if (this.asset$.value?.isLive() === true) {
      this.type$.value = this._livePlayerType();
    }
    if (this.asset$.value?.isFastTV === true && this.currentPlayerElt !== null && this.currentPlayerElt !== undefined) {
      this._programService = new FastTVProgramService(this.asset$.value.resource.assetId, this.currentPlayerElt);
    }
  }

  seekToLive() {
    this.type$.value = this._livePlayerType();
    super.seekToLive();
  }

  async destroy() {
    await super.destroy();
    this._programService?.destroy();
    this._programService = null;
    return true;
  }
}
