import "./programTileView.scss";

import { AcceptsMouseFocusView } from "~/components/views/common/mouseSupport/acceptsMouseFocusView";
import { FavorisView } from "~/components/views/favorisView/favorisView";
import { DS } from "~/libs";
import { ProgramCard } from "~/utils/rtbf/models";

export class ProgramTileView extends AcceptsMouseFocusView {
  constructor(program: ProgramCard, widgetSize: "mosaicSize" | "listSize") {
    super(program.id, `programTileView ${widgetSize}`);

    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "cardContainer",
      style: {
        backgroundImage: program.illustration !== null ? `url(${program.illustration.s})` : undefined,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    if (program.illustration === null)
      DS.DOMHelper.createElement({
        tagName: "div",
        parent: this.rootElement,
        className: "title",
        innerText: `${program.title}`,
      });

    this.rootElement.appendChild(new FavorisView(program.id).rootElement);
  }
}
