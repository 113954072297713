import "./main.scss";

import { DS } from "~/libs";
import { deeplinkManager } from "~/tools/deeplinkManager";

import { CallFlow } from "./callflow";
import { env } from "./datas";
import { LogConsentPageView } from "./pages/consent/log/logConsentPage";
import { PopupPage } from "./pages/popup/popupPage";
import { splashPageSingleton } from "./pages/splash/splashPage";
import { PageTestView } from "./pages/test/pageTest";
import { LogConsentHelper } from "./tools/logConsentHelper";
import { networkManager } from "./tools/networkManager";
import { StopWatch } from "./tools/time";

// only show debug stuff & version app overlay on non-releases
if (!_IS_RELEASE_) {
  DS.DOMHelper.addStyleSheet(`
  #versionApp {
    position: absolute;
    right: 10px;
    top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: rgba(255, 255, 255, 0.62);
    background-color: rgba(0, 0, 0, 0.25);
    html[dir="rtl"] { 
      left: 10px;
      right: auto;
      text-align: right;
    }
  }
`);

  // debug version
  const versionApp = document.querySelector("#versionApp") as HTMLElement;
  versionApp.innerText = `${_APP_VERSION_}-${_APP_HASH_}-${_IS_DEV_ ? "dev" : "prod"}`;
}

// it's *CRITICAL* this is created before any other list, as it's defining if a list supports mouse or not
// the rootMenu uses list, and if it's imported before this stack is created it won't be created as mouse-compatible
Log.app.log("mark: createNavStack");
export const navigationStack = DS.createNavigationStack({
  rootElement: document.getElementById("navigationStack")!,
  // keyThrottle: 150,
  wheelThrottle: DS.platform.type === DS.PlatformType.other ? 250 : undefined,
  onExit: exitApp,
});
!_IS_RELEASE_ && (window.stack = navigationStack);

const loadingStopWatch = new StopWatch();

// the app entry point. Nothing should be done / created / instantiated before that is called
async function startApp() {
  Log.app.log("mark: startApp");

  // Init network
  networkManager.init();

  // Init deeplink management
  deeplinkManager.init();

  // push the splash over the top
  loadingStopWatch.start();
  navigationStack.pushPage(splashPageSingleton);

  await CallFlow.initHome(true, loadingStopWatch);
}

export function exitApp(): boolean {
  Log.app.log("QUIT THE APP");
  if (![DS.PlatformType.ps4, DS.PlatformType.ps5].includes(DS.platform.type)) {
    navigationStack.pushPage(
      new PopupPage({
        title: "Souhaitez-vous quitter l'appli ?",
        description: "",
        button1: {
          label: "Oui",
          action: () => {
            DS.platform.exit();
          },
        },
        button2: {
          label: "Non",
          action: () => {},
        },
      })
    );
  }

  return false;
}

export function hideSplash() {
  const splashElement = document.getElementById("splash");
  splashElement?.parentElement?.removeChild(splashElement);
}

window.dotscreen = {};

window.onload = async () => {
  document.addEventListener(
    "keydown",
    ev => {
      const key = DS.mapKeyboardEvent(ev);
      if (key === DS.Keys.select && ev.repeat) return;

      if (key === DS.Keys.green && env() === "UAT") {
        navigationStack.pushPage(new PageTestView());
      }

      // if log handles key we don't want the app to process the key
      if (DS.LogUI.keyHandler(key, "keydown")) return;
      navigationStack.keyHandler(key);
    },
    false
  );

  void startApp();

  document.getElementById("htmlSpinner")?.remove();

  // For debugging purpose
  window.dotscreen.showLogConsentForTesting = async (consentType: "mandatory" | "optional" = "mandatory") => {
    await LogConsentHelper.getConsentDetails();
    navigationStack.pushPage(new LogConsentPageView(consentType === "mandatory"));
  };
};
