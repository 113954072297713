import "./mediaPremiumWidgetView.scss";

import { MediaPremiumTileView } from "~/components/tiles/mediaPremium/mediaPremiumTileView";
import { mouseSupportArrowHoriParams } from "~/components/views/common/mouseSupport/mouseSupportArrow";
import { APIAuvio } from "~/datas/api/apiAuvio";
import { DS } from "~/libs";
import { GenericPage } from "~/pages/generic/genericPage";

import { Config } from "../../../config";
import { navigationStack } from "../../../main";
import { RTBF } from "../../../utils/rtbf";
import { FocusTracker } from "../../focusTracker";
import { LoadingTile } from "../../tiles/loading/loadingTile";
import { MoreContentTile } from "../../tiles/moreContent/moreContent";
import { WidgetView } from "../widgetView";

/**
 * Category widget, built by the GenericPage.
 * @param widget The category widget.
 */
export class MediaPremiumWidgetView extends WidgetView {
  private _focusTracker?: FocusTracker;

  constructor(widget: RTBF.Widget) {
    super(widget.id, "mediaPremiumWidget premiumFocus");
    this._setAnalytics(widget);

    // Widget title
    DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "widgetTitle",
      innerText: widget.title,
    });
    this._addSubtitleIfAvailable(widget);
    // Widget list of content
    this.delegate = DS.createListComponent(
      {
        id: `${widget.id}/list`,
        className: "widgetList",
        modelSource$: (this.modelSource = new DS.ModelSource<RTBF.MediaPremiumCard | RTBF.CtaCard>(
          this._content(widget)
        )),
        viewFactory: card => {
          switch (card.resourceType) {
            case "MEDIA_PREMIUM":
              return new MediaPremiumTileView(card, "listSize");
            case "CTA":
              return new MoreContentTile(card);
          }
        },
        loadingPlaceholderFactory: () => new LoadingTile(),
        scrollingMode: { type: DS.ScrollingType.page, horizontal: true },
        scrollDuration: Config.scrollDuration,
        mouseSupport: Config.mouseSupport && mouseSupportArrowHoriParams(),
        onSelect: (card, index) => {
          if (card.resourceType === "MEDIA_PREMIUM") {
            super._analyticsCards({
              card_order: index,
              media_id: card.id,
              media_title: card.title,
              media_type: card.type === "VIDEO" ? "premium_vod" : "aod",
              media_category: card.categoryLabel,
              media_program: card.channelLabel,
              media_duration: card.duration,
            });
          }
          navigationStack.pushPage(new GenericPage(card.path));
          return true;
        },
      },
      list => {
        this._focusTracker = new FocusTracker(list, "focusRect", "cardContainer");
        this.collectRelease(this._focusTracker.onRelease);
      }
    );
  }

  /**
   * Fetch mediaPremium list list and return it, with a CTA if available, if contentPath is defined
   * Otherwise it returns the content stored in the widget object in the constructor (used for search)
   *
   * @param {RTBF.Widget} widget
   * @return {Promise<(RTBF.MediaPremiumCard | RTBF.CtaCard)[]>} List of items
   */
  private async _content(widget: RTBF.Widget): Promise<(RTBF.MediaPremiumCard | RTBF.CtaCard)[]> {
    try {
      const content: (RTBF.MediaPremiumCard | RTBF.CtaCard)[] = (
        widget.contentPath !== undefined
          ? await APIAuvio.widget(widget.contentPath, RTBF.WidgetMediaPremiumList)
          : RTBF.WidgetMediaPremiumList.parse(widget)
      ).content;
      if (widget.cta !== undefined && widget.cta !== null) {
        content.push(widget.cta);
      }
      return content;
    } catch (error) {
      Log.app.error("Error while fetching and parsing ", error);
      return [];
    }
  }
}
