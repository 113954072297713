import { IRedBeePlayerAnalyticsOptions, IRedBeePlayerOptions } from "@redbeemedia/javascript-player";

import { APIRedbee, Config } from "~/datas";
import { APIAuvio } from "~/datas/api/apiAuvio";
import { APIQuantumCast } from "~/datas/api/apiQuantumCast";
import { Listenable } from "~/libs/ui/helpers/Listenable";
import { navigationStack } from "~/main";
import { VPNPlayerErrorPage } from "~/pages/player/playerErrorPage";
import { PlayerPage } from "~/pages/player/playerPage";
import { LanguageSelectionButtons } from "~/pages/settings/languageSelection/languageSelectionPage";
import { OffrePremiumPage } from "~/pages/settings/offrePremium/offrePremiumPage";
import { IAdsOptions } from "~/player/redbee/definitions";
import { IPlayerEventTime } from "~/player/shaka/definitions";
import { ISpriteCue } from "~/player/spriteVTTParser";
import { getDeviceUUID } from "~/tools/deviceHelper";
import { DevicePreferenceHelper } from "~/tools/devicePreferencesManager";
import { showErrorRetryPopup } from "~/tools/errorPageHelper";
import { ParentalControlHelper } from "~/tools/parentalControlHelper";
import {
  BingeOptions,
  IPlayerAsset,
  IPlayerCommon,
  IPlayerLoadOptions,
  IPlayerQuality,
  IPlayerTrack,
  PlayerError,
  PlayerState,
  PlayerStreamType,
  PortabilityStatus,
} from "~/tools/player";
import { PremiumHelper } from "~/tools/premiumHelper";
import { convertSecondToPlayerDuration } from "~/tools/time";
import { GemiusPlayerEvent, playerEventsMapping, TrackingHelper } from "~/tools/trackingHelper";
import { REDBEE } from "~/utils/redbee";
import { ProgramChangedData } from "~/utils/redbee/models/epg";
import { RedBeeError } from "~/utils/redbee/models/error";
import { RTBF } from "~/utils/rtbf";
import { EmbedLive, EmbedMeta, EmbedType, TrackingData } from "~/utils/rtbf/models";

import { APIGigyaOIDC } from "../datas/api/apiGigyaOIDC";
import { QUANTUMCAST } from "../utils/quantumcast";

export abstract class PlayerCommon implements IPlayerCommon {
  // constants
  protected static _DEFAULT_INCREMENT = 10;
  protected static _RADIO_INCREMENT_RW = 15;
  protected static _RADIO_INCREMENT_FW = 30;
  protected _TIME_INCREMENT_FW = PlayerCommon._DEFAULT_INCREMENT;
  protected _TIME_INCREMENT_RW = -PlayerCommon._DEFAULT_INCREMENT;
  static _AD_REPLACEMENT_MAX_PAUSE = 10;

  // Listenables
  currentTime$ = new Listenable(0);
  seekTime$ = new Listenable<number | undefined>(undefined);
  duration$ = new Listenable(0);
  seekable$ = new Listenable({ start: 0, end: 0 });
  state$ = new Listenable(PlayerState.IDLE);
  asset$ = new Listenable<IPlayerAsset | undefined>(undefined);
  type$ = new Listenable<PlayerStreamType | undefined>(undefined);
  showControls$ = new Listenable(false);
  showSpinner$ = new Listenable(true);
  percentRatio$ = new Listenable(0);
  metadataRadioLive$?: Listenable<QUANTUMCAST.MetadataChannel | undefined>;
  adReplacement$ = new Listenable<number | undefined>(undefined);
  private _metadataRadioLiveUnReg?: () => void;
  protected _backIntent = false;

  protected _options: IRedBeePlayerOptions;
  protected _analytics: IRedBeePlayerAnalyticsOptions;
  video?: unknown;

  assetBackup: IPlayerAsset | undefined;
  restarting = false;

  private _adReplacementInterval = -1;

  private _trackPlayerStateUnregister?: () => void = undefined;
  private _bingeUnReg: (() => void) | undefined = undefined;
  private _disableNextBinge = false;

  constructor() {
    this._options = {
      customer: Config().REDBEE.customer,
      businessUnit: Config().REDBEE.businessUnit,
      exposureBaseUrl: Config().REDBEE.exposureBaseUrl,
    };

    this._analytics = {
      appName: Config().REDBEE.appName,
      disabled: false,
    };
  }

  abstract playerInitTime: number;
  abstract currentPlayerElt?: (HTMLMediaElement & { mux?: IMuxEmbedInstancePlugin }) | null;

  /**
   * Get the actual audio track.
   */
  abstract audioTrack: IPlayerTrack;
  /**
   * Get the actual audio track.
   */
  abstract subtitleTrack: IPlayerTrack;
  /**
   * Set the quality of the player.
   */
  abstract quality: IPlayerQuality;

  /**
   * Attach the player to a div
   */
  abstract attach(containerElement: HTMLElement, audioOnly?: boolean): void;

  /**
   * Load an asset
   */
  abstract load(assetID: string, embedType: EmbedType, options?: IPlayerLoadOptions): Promise<void>;

  /**
   * Check if the content is playing.
   */
  abstract isPlaying(): boolean;

  /**
   * Forward the stream
   */
  abstract forward(preview?: boolean): void;

  /**
   * Rewind the stream
   */
  abstract rewind(preview?: boolean): void;

  /**
   * Get all audio tracks.
   */
  abstract audioTracks(): IPlayerTrack[];

  /**
   * Get all audio tracks.
   */
  abstract subtitleTracks(): IPlayerTrack[];

  /**
   * Seek with an offset.
   * @param offset The offset
   * @param preview If the seek is a preview, meaning just update the progress bar.
   */
  abstract seekTo(time?: number): void;

  /**
   * Go to the current live timestamp.
   */
  abstract seekToLive(): void;

  /**
   * Get all quality levels. The first one is the auto quality level.
   */
  abstract qualityLevels(): IPlayerQuality[] | undefined;

  abstract applySubtitleStyle(): void;

  abstract positionSubtitles(playerControls?: HTMLElement): void;

  abstract getTimelineSpriteCues(width?: number): Promise<ISpriteCue[]>;

  abstract getCurrentTime(isRadio: boolean): number;

  registerBackIntent() {
    this._backIntent = true;
  }

  isLoading(): boolean {
    return [PlayerState.LOADING, PlayerState.IDLE].indexOf(this.state$.value) !== -1;
  }

  /**
   * Pause the content
   */
  pause(isUserAction = false) {
    // Only events triggered by the user must be sent to Gemius
    isUserAction && this.trackProgramEvent(GemiusPlayerEvent.pause);
  }

  // WARNING: This function must be kept as efficient as possible
  eventTimeUpdate(data: IPlayerEventTime) {
    this.currentTime$.value = data.currentTime;
    this.duration$.value = data.duration;
    this.seekable$.value = data.seekable;

    // Here I use a if else conditional so if the event is not from a live,
    // We skip the variable attribution step and the calculations.
    if (this.asset$.value?.isLive() === true) {
      if (this.asset$.value.resource.radio !== undefined) {
        this.percentRatio$.value = 1;
      } else {
        // We substract 100 because sometimes the stream end just before the given date.
        const end = this.asset$.value?.resource.duration - 100;
        const current =
          end - Math.floor((this.asset$.value.resource.live?.end.getTime() ?? 1) - data.currentTime * 1000);

        this.percentRatio$.value = Math.min(Math.floor((current * 100) / end), 100);

        // After 98 percent of streaming, every 30 seconds we refresh the asset
        if (this.percentRatio$.value > 98 && this.asset$.value.timestamp < Date.now() - 30 * 1000) {
          void this._loadAsset(this.asset$.value.resource.id, "LIVE");
        }
      }
    } else {
      this.percentRatio$.value = Math.floor((data.currentTime * 100) / data.duration);
    }

    //Log.player.warn("Player percentage: ", this.percentRatio$.value);

    if (this.percentRatio$.value === 5) this._trackAnalytics("reached_5", data.audioTrack, data.subtitleTrack);
    if (this.percentRatio$.value === 50) this._trackAnalytics("reached_50", data.audioTrack, data.subtitleTrack);
    if (this.percentRatio$.value === 95) this._trackAnalytics("reached_95", data.audioTrack, data.subtitleTrack);
  }

  eventAdReplacement(duration: number) {
    window.clearInterval(this._adReplacementInterval);
    this.adReplacement$.value = duration;
    this._adReplacementInterval = window.setInterval(() => {
      if (this.adReplacement$.value === undefined) {
        this.adReplacement$.value = duration;
      } else if (this.adReplacement$.value - 1000 <= 0) {
        window.clearInterval(this._adReplacementInterval);
        this.adReplacement$.value = undefined;
      } else {
        this.adReplacement$.value = this.adReplacement$.value - 1000;
      }
    }, 1000);
  }

  trackProgramEvent(event: GemiusPlayerEvent) {
    if (this.asset$.value?.resource.id !== undefined) {
      if (this.asset$.value?.isTrailer === true)
        TrackingHelper.trackAdEvent(this.asset$.value, event, this.currentTime$.value);
      else TrackingHelper.trackProgramEvent(this.asset$.value.resource.id, event, this.currentTime$.value);
    }
  }

  trackAnalytics(action: string, gtag?: Partial<TrackingData>) {
    this._trackAnalytics(action, this.audioTrack, this.subtitleTrack, gtag);
  }

  private _trackAnalytics(
    action: string,
    audioTrack?: IPlayerTrack,
    subtitleTrack?: IPlayerTrack,
    gtag?: Partial<TrackingData>
  ) {
    if (this._analytics.disabled === true) return;
    let media_type: "live_video" | "live_audio" | "vod" | "aod" | "premium_vod" | "trailer_video" = "vod";
    switch (this.type$.value) {
      case PlayerStreamType.AUDIO:
        media_type = this.asset$.value?.isPremium() === true ? "premium_vod" : "aod";
        break;
      case PlayerStreamType.VIDEO:
        media_type = "vod";
        break;
      case PlayerStreamType.AUDIO_LIVE:
      case PlayerStreamType.AUDIO_SHIFT:
        media_type = "live_audio";
        break;
      case PlayerStreamType.VIDEO_LIVE:
      case PlayerStreamType.VIDEO_SHIFT:
      case PlayerStreamType.VIDEO_LIVE_AD_REPLACEMENT:
      case PlayerStreamType.VIDEO_SHIFT_AD_REPLACEMENT:
        media_type = "live_video";
        break;
    }
    const quality = this.qualityLevels();

    TrackingHelper.track({
      event_name: "player_ui_interaction",
      gtag: {
        action,
        media_type,
        media_category: this.asset$.value?.resource.embed?.category?.label,
        media_program: this.asset$.value?.resource.embed?.program?.title,
        media_duration: this.asset$.value?.resource.duration,
        media_title: this.asset$.value?.resource.title,
        media_id: this.asset$.value?.resource.id,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        media_current_quality: quality ? quality[0]?.name : undefined,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        media_current_audiostream: audioTrack ? audioTrack.id : undefined,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        media_current_subtitle: subtitleTrack ? subtitleTrack.id : undefined,
        ...gtag,
      },
    });
  }

  private async _snowplowTracking(isRadio: boolean) {
    void TrackingHelper.snowplowEvent("init", {
      timestamp: this.asset$.value?.timestamp.toString() ?? "",
      media_id: this.asset$.value?.resource.id ?? "",
      is_live: this.isLive(),
      is_audioonly: this.audioOnly,
      is_highlight: false,
      user_id: APIGigyaOIDC.userInfo$.value?.uid ?? "NULL",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      pseudo_user_id: getDeviceUUID(),
      client_ip: "",
      platform: TrackingHelper.snowplowPlatform(),
      useragent: navigator.userAgent,
      device: "smarttv",
      product: "Auvio",
      contexte: Number.parseInt(this.asset$.value?.resource.id ?? "0"),
      pageurl: "",
      page_referrer: "",
    });

    this._trackPlayerStateUnregister = this.state$.didChange(
      (state, previousState) => {
        if (previousState === "loading") void TrackingHelper.snowplowEvent("start");

        switch (state) {
          case "paused":
          case "seeking":
          case "playing":
            void TrackingHelper.snowplowEvent(playerEventsMapping[state], {
              offset: this.getCurrentTime(isRadio),
            });
            break;
          default:
            break;
        }
      },
      null,
      isRadio
    );
  }

  get TIME_INCREMENT_FW() {
    return this._TIME_INCREMENT_FW;
  }

  get TIME_INCREMENT_RW() {
    return this._TIME_INCREMENT_RW;
  }

  get muted() {
    return this._options.muted ?? false;
  }

  set muted(value: boolean) {
    this._options.muted = value;
  }

  get autoplay() {
    return this._options.autoplay ?? true;
  }

  set autoplay(value: boolean) {
    this._options.autoplay = value;
  }

  get audioOnly() {
    return this._options.audioOnly ?? false;
  }

  set audioOnly(value: boolean) {
    this._options.audioOnly = value;
  }

  get analytics() {
    return !(this._analytics.disabled ?? false);
  }

  set analytics(value: boolean) {
    this._analytics.disabled = !value;
  }

  get ads() {
    return this._options.ads ?? undefined;
  }

  set ads(obj: IAdsOptions | undefined) {
    this._options.ads = obj;
  }

  private async _sendStopEvent() {
    if (this.asset$.value != null && !this.asset$.value?.isLive()) {
      await APIAuvio.sendStopEvent();
    }
  }

  async destroy() {
    void this._sendStopEvent();

    // Stop Gemius tracking
    this.trackProgramEvent(GemiusPlayerEvent.close);
    TrackingHelper.disposeGemiusPlayer();

    this._trackPlayerStateUnregister?.();
    this._trackPlayerStateUnregister = undefined;
    try {
      this.asset$.value?.isTrailer === false &&
        TrackingHelper.snowplowEvent("abort", {
          offset: this.getCurrentTime(this.asset$.value?.resource.radio !== undefined),
        });
    } catch (error) {
      Log.player.error("Error while retrieving current time for snowplow analytics", error);
    }

    // Reset all listeners
    this.currentTime$.value = 0;
    this.seekTime$.value = undefined;
    this.duration$.value = 0;
    this.seekable$.value = { start: 0, end: 0 };
    this.state$.value = PlayerState.IDLE;
    this.asset$.value = undefined;
    this.type$.value = undefined;
    this.metadataRadioLive$ && (this.metadataRadioLive$.value = undefined);
    this._metadataRadioLiveUnReg?.();
    this._bingeUnReg?.();
    this._disableNextBinge = false;

    this.showControls$.value = true;
    this.percentRatio$.value = 0;

    TrackingHelper.resetLastProgramEvent();

    if (
      this.currentPlayerElt != null &&
      this.currentPlayerElt.mux != null &&
      this.currentPlayerElt.mux.deleted === false
    ) {
      this.currentPlayerElt.mux.destroy();
      this.currentPlayerElt.mux = undefined;
    }

    return this.video !== "undefined";
  }

  async getToken() {
    if ((await APIRedbee.sessionToken()) !== undefined) {
      this._options.sessionToken = await APIRedbee.sessionToken();
      this._options.deviceId = getDeviceUUID();
    }
  }

  async loadAsset(assetID: string, embedType: EmbedType, isTrailer = false, isRadioLive = false) {
    if (!(isTrailer || isRadioLive)) {
      await this._loadAsset(assetID, embedType);
      await this._checkLocation();
      await this._checkParental();
    } else if (isRadioLive) {
      // Call empty playerAdsMeta for radio lives to send correct data to RedBee
      isRadioLive === true && this._playerAdsMeta({});
    } else if (isTrailer) {
      await this._loadAsset(assetID, embedType, true);
    }
  }

  async getRadioData(assetID: string, isRadioLive = false) {
    let radio: { data: RTBF.EmbedRadio; meta: RTBF.EmbedMeta } | undefined = undefined;
    if (isRadioLive) radio = await APIAuvio.embedRadio(assetID);
    return radio;
  }

  getAssetId(
    assetID: string,
    radio?: { data: RTBF.EmbedRadio; meta: RTBF.EmbedMeta },
    isRadioLive = false,
    isTrailer = false
  ) {
    let assetId =
      this.asset$.value?.resource.assetId != null && this.asset$.value?.resource.assetId
        ? this.asset$.value?.resource.assetId
        : this.asset$.value?.resource.embed?.streamId; // Some live kids contents have streamId instead of assetId
    if (isTrailer) {
      assetId = assetID;
    } else if (isRadioLive && radio !== undefined) {
      assetId = radio.data.channel?.streamId ?? assetID;
    }
    return assetId ?? "";
  }

  async initTracking(videoElement: HTMLElement) {
    try {
      await TrackingHelper.initGemiusPlayer(videoElement);
      if (this.asset$.value !== undefined) {
        if (this.asset$.value.isTrailer) TrackingHelper.trackPromo(this.asset$.value);
        else TrackingHelper.trackProgram(this.asset$.value);
      }
    } catch (e: unknown) {
      Log.player.error("Can't init gemius in player load: ", e);
    }
  }

  async loadRadioLive(isRadioLive = false, radio?: { data: RTBF.EmbedRadio; meta: RTBF.EmbedMeta }) {
    if (isRadioLive && radio !== undefined) await this._loadRadioLive(radio);
  }

  protected async _loadAsset(id: string, embedType: EmbedType, isTrailer = false) {
    const embed = embedType === "LIVE" ? await APIAuvio.embedLive(id) : await APIAuvio.embedMedia(id);
    if (embed === undefined) return;
    const embedLive = EmbedLive.safeParse(embed.data);

    const asset: IPlayerAsset = {
      isLive: () => {
        return embedType === "LIVE";
      },
      isFastTV: embedLive.success && embedLive.data.channel?.fastTv === true,
      isPremium: () => {
        return "isPremium" in embed.data && embed.data.isPremium === true;
      },
      isTrailer,
      meta: embed.meta,
      type: embedType,
      resource: {
        id: embed.data.id,
        assetId: embed.data.assetId ?? "",
        title: embed.data.title,
        subtitle: embed.data.subtitle,
        background: {
          s: embed.data.background?.xs ?? "",
          m: embed.data.background?.m ?? "",
        },
        description: embed.data.description,
        embed: embed.data,
        geoloc: { ...embed.data.geoloc, key: embed.data.geoloc.key.toUpperCase() },
        duration: embed.data.duration,
        isAdReplacement: false,
      },
      recommended: undefined,
      binge: embed.data?.next ?? undefined,
      timestamp: Date.now(),
    };

    if (embedLive.success) {
      if (embedLive.data.scheduledFrom !== undefined && embedLive.data.scheduledTo !== undefined) {
        const scheduledFrom = embedLive.data.scheduledFrom ?? "";
        const scheduledTo = embedLive.data.scheduledTo ?? "";
        if (scheduledFrom !== "" && scheduledTo !== "")
          asset.resource.duration = new Date(scheduledTo).getTime() - new Date(scheduledFrom).getTime();
        asset.resource.live = {
          start: new Date(scheduledFrom),
          end: new Date(scheduledTo),
        };
      }
      if (embedLive.data.isAdReplacement === true) {
        asset.resource.isAdReplacement = true;
        asset.resource.assetId = embedLive.data.streamId ?? embedLive.data.assetId ?? "";
        this.type$.value = PlayerStreamType.VIDEO_LIVE_AD_REPLACEMENT;
      } else if (embedLive.data.channel?.fastTv === true) {
        asset.resource.assetId = embedLive.data.streamId ?? "";
      }
    }

    this._playerAdsMeta(embed.meta);

    this.asset$.value = asset;
    this.assetBackup = this.asset$.value;
    // Make sure to make an async call with recommendations.
    if (!isTrailer) {
      void this._loadRecommandation(id, embedType);

      void this._snowplowTracking(false);
    }
  }

  private async _loadRecommandation(id: string, embedType: EmbedType) {
    if (this.asset$.value?.isFastTV === true) {
      return;
    }
    const reco = await APIAuvio.widgetRecommandation(id, embedType);
    if (this.asset$.value !== undefined && reco !== undefined && reco.data.length > 0) {
      this.asset$.value = {
        ...this.asset$.value,
        recommended: reco,
      };
    }
  }

  private async _loadRadioLive(radio: { data: RTBF.EmbedRadio; meta: RTBF.EmbedMeta }) {
    // The metadataRadioLive$ is set to undefined to close the websocket
    this.metadataRadioLive$ && (this.metadataRadioLive$.value = undefined);
    this._metadataRadioLiveUnReg?.();
    this.metadataRadioLive$ = await APIQuantumCast.metadataRadioLive(radio?.data.channel?.streamId ?? "");

    this._metadataRadioLiveUnReg = this.metadataRadioLive$.didChange(
      value => {
        if (value !== undefined) {
          const radioImg =
            value?.extdata?.VisuelEmission ?? value?.extdata?.VisuelChaine ?? radio.data.channel?.logo?.dark.png ?? "";
          this.asset$.value = {
            isLive: () => true,
            isPremium: () => false,
            isFastTV: false,
            isTrailer: false,
            meta: radio?.meta,
            type: "LIVE",
            resource: {
              id: value.id,
              assetId: radio?.data.channel?.streamId ?? "",
              title: value?.extdata?.TitreEmission ?? radio.data.channel?.label ?? "",
              subtitle:
                value.extdata?.Presentateur !== undefined
                  ? `${t("player.radio.presented")} ${value?.extdata?.Presentateur}`
                  : ``,
              background: {
                s: radioImg,
                m: radioImg,
              },
              description: value?.extdata?.SloganChaine ?? "",
              live: {
                start: new Date(value.start_timestamp * 1000),
                end: new Date(value.start_timestamp * 1000 + value.duration),
                startText: value?.extdata?.HeureDebut ?? undefined,
                endText: value?.extdata?.HeureFin ?? undefined,
              },
              radio: value,
              ramRadio: radio.data,
              duration: value.duration / 1000,
              isAdReplacement: false,
            },
            recommended: undefined,
            binge: undefined,
            timestamp: Date.now(),
          };
          this.assetBackup = this.asset$.value;
          TrackingHelper.trackProgram(this.asset$.value);
        }
      },
      null,
      true
    );
    void this._snowplowTracking(true);
  }

  /*
   * Give ads parameters to the player
   * @param {EmbedMeta} embedMeta
   * @return {void}
   */
  private _playerAdsMeta(embedMeta: EmbedMeta): void {
    const ads = {};
    Object.assign(ads, {
      consent: window.Didomi?.getCurrentUserStatus?.()?.consent_string,
      ifa: getDeviceUUID(),
      gdprOptin: true,
      mute: this.muted,
    });
    if (embedMeta.aip !== undefined) {
      Object.assign(ads, embedMeta.aip);
    }
    if (typeof embedMeta.adsWizz === "object" && embedMeta.adsWizz !== null) {
      Object.assign(ads, {
        ...embedMeta.adsWizz,
        ...{
          "aw_0_req.userConsentV2": window.Didomi?.getCurrentUserStatus?.()?.consent_string,
          listenerId: "",
        },
      });
    }
    this.ads = ads;
  }

  /**
   * Callflow to check if the user is able to launch the stream.
   * This function is able to destroy the player.
   */
  private async _checkLocation() {
    const portabilite = this._portabilityStatus();
    let userLoc;
    try {
      userLoc = await APIRedbee.getLocation();
    } catch (e) {
      throw new Error();
    }
    const mediaLoc = this.asset$.value?.resource.geoloc?.key ?? "BE";
    if (APIGigyaOIDC.isConnected() !== true) {
      await this.destroy();
      if (userLoc.countryCode in REDBEE.Portability || !(mediaLoc in REDBEE.Portability)) {
        throw new PlayerError(2, "Player location - need to be connected");
      } else {
        throw new PlayerError(3, "Player location - impossible to play");
      }
    } else if (userLoc.countryCode === "BE" || !(mediaLoc in REDBEE.Portability)) {
      // User is connected and in belgium or user is connected and media is in world
      return;
    } else if (userLoc.countryCode in REDBEE.Portability) {
      // User is connected in EU, media is in EU
      if (mediaLoc === "BE") {
        // media is in BE
        if (portabilite === "inactive" || portabilite === "expired") {
          console.error("Player location - portability not ok");
          throw new PlayerError(4, "Player location - portability not ok");
        } else if (portabilite === "noresident") {
          console.error("Player location - impossible to play");
          // User can't have access and can't have portability
          throw new PlayerError(3, "Player location - impossible to play");
        }
      }
    } else {
      // User is in WORLD
      throw new PlayerError(3, "Player location - impossible to play");
    }
    return;
  }

  private _portabilityStatus(): PortabilityStatus {
    if (APIGigyaOIDC.userInfo$.value?.data?.belgian === true) {
      const belgianVerifiedTimestamp = APIGigyaOIDC.userInfo$.value?.data?.belgianVerifiedTimestamp;
      if (belgianVerifiedTimestamp !== undefined && belgianVerifiedTimestamp < 13 * 30 * 24 * 60 * 60 * 1000) {
        return "active";
      } else if (belgianVerifiedTimestamp !== undefined) {
        return "expired";
      } else {
        return "inactive";
      }
    } else {
      if (
        APIGigyaOIDC.userInfo$.value?.address?.prefix === "32" &&
        APIGigyaOIDC.userInfo$.value?.address?.country === "BE"
      ) {
        return "inactive";
      } else {
        return "noresident";
      }
      return "noresident";
    }
  }

  private async _checkParental() {
    if (
      this.asset$.value?.resource.embed?.rating !== undefined &&
      !(await ParentalControlHelper.isParentalAuthorized(this.asset$.value?.resource.embed?.rating))
    ) {
      throw new PlayerError(5, "Player rating - parental control");
    }
  }

  play(firstStart = false) {
    if (!firstStart && this.currentTime$.value < this.duration$.value) {
      switch (this.type$.value) {
        case PlayerStreamType.VIDEO_LIVE:
        case PlayerStreamType.VIDEO_SHIFT:
          this.type$.value = PlayerStreamType.VIDEO_SHIFT;
          break;
        case PlayerStreamType.AUDIO_LIVE:
        case PlayerStreamType.AUDIO_SHIFT:
          this.type$.value = PlayerStreamType.AUDIO_SHIFT;
          break;
        case PlayerStreamType.VIDEO_LIVE_AD_REPLACEMENT:
        case PlayerStreamType.VIDEO_SHIFT_AD_REPLACEMENT:
          this.type$.value = PlayerStreamType.VIDEO_SHIFT_AD_REPLACEMENT;
          break;
        case PlayerStreamType.VIDEO_FAST_TV_PAUSED:
          this.seekToLive();
          break;
      }
    }
    this.hideTooltip();
  }

  private _resetTooltip() {
    const spriteCueElement = document.querySelector(".spriteCue") as HTMLElement | null;
    if (spriteCueElement) {
      spriteCueElement.removeAttribute("style");
      spriteCueElement.innerText = "";
    }
  }

  private async _displayTooltip(position: number) {
    this._resetTooltip();

    const width = 160;
    const height = 90;
    const list = await this.getTimelineSpriteCues(width);

    const spriteCue = list?.find(cue => cue.start <= position && cue.end > position);
    if (spriteCue && spriteCue.image) {
      const container = document.querySelector(".spriteCueTooltip") as HTMLElement | null;
      if (container) {
        const tooltip = container.getElementsByClassName("spriteCue")[0] as HTMLElement | null;
        if (tooltip) {
          tooltip.style.width = String(spriteCue.dimensions.width ?? width);
          tooltip.style.height = String(spriteCue.dimensions.height ?? height);
          tooltip.style.backgroundImage = `url(${spriteCue.image})`;
          tooltip.style.backgroundPosition = `-${spriteCue.dimensions.x ?? 0}px -${spriteCue.dimensions.y ?? 0}px`;
          tooltip.innerText = convertSecondToPlayerDuration(position);

          (<HTMLElement>container.children[0]).classList.add("visible");
        }
      }
    }
  }

  hideTooltip() {
    this._resetTooltip();
    const elem = document.querySelector(".spriteCueTooltip");
    if (elem) (<HTMLElement>elem.children[0]).classList.remove("visible");
  }

  updateType() {
    if (this.type$.value === PlayerStreamType.VIDEO_LIVE) {
      this.type$.value = PlayerStreamType.VIDEO_SHIFT;
    } else if (this.type$.value === PlayerStreamType.AUDIO_LIVE) {
      this.type$.value = PlayerStreamType.AUDIO_SHIFT;
    } else if (this.type$.value === PlayerStreamType.VIDEO_LIVE_AD_REPLACEMENT) {
      this.type$.value = PlayerStreamType.VIDEO_SHIFT_AD_REPLACEMENT;
    }
    if (this.type$.value === PlayerStreamType.VIDEO_FAST_TV) {
      this.type$.value = PlayerStreamType.VIDEO_FAST_TV_PAUSED;
    }
  }

  seek(offset: number, preview = false) {
    setTimeout(() => (this.state$.value = PlayerState.SEEKING), 0);
    let playbackPos = Math.min(
      this.duration$.value,
      Math.max(0, (this.seekTime$.value ?? this.currentTime$.value) + offset)
    );
    if (
      this.isLive() &&
      this.type$.value !== PlayerStreamType.AUDIO_LIVE &&
      this.type$.value !== PlayerStreamType.AUDIO_SHIFT &&
      playbackPos * 1000 < new Date(2000, 0).getTime()
    ) {
      return false;
    }
    if (
      this.asset$.value?.resource.isAdReplacement === true &&
      this.seekTime$.value !== undefined &&
      offset < 0 &&
      this.seekTime$.value + offset + 60 * PlayerCommon._AD_REPLACEMENT_MAX_PAUSE < Date.now() / 1000
    ) {
      playbackPos = this.duration$.value - 60 * PlayerCommon._AD_REPLACEMENT_MAX_PAUSE;
    }
    void this._displayTooltip(playbackPos);
    if (preview) {
      this.seekTime$.value = playbackPos;
    } else {
      this.seekTime$.value = undefined;
      this.seekTo(playbackPos);
    }
  }

  cancelSeek() {
    if (this.state$.value === PlayerState.SEEKING) {
      if (
        this.type$.value === PlayerStreamType.VIDEO_LIVE ||
        this.type$.value === PlayerStreamType.VIDEO_SHIFT ||
        this.type$.value === PlayerStreamType.AUDIO_LIVE ||
        this.type$.value === PlayerStreamType.AUDIO_SHIFT ||
        this.type$.value === PlayerStreamType.VIDEO_LIVE_AD_REPLACEMENT ||
        this.type$.value === PlayerStreamType.VIDEO_SHIFT_AD_REPLACEMENT
      ) {
        this.seekToLive();
      }
      this.seekTime$.value = undefined;
    }
  }

  isLive() {
    return (this.asset$.value?.isLive() ?? false) || this.asset$.value?.type === "LIVE";
  }

  isPremium() {
    return this.asset$.value?.isPremium() ?? false;
  }

  get lastKnownAsset() {
    return this.assetBackup;
  }

  protected _loadError(error: unknown, catchError: boolean, reloadFn: () => void) {
    const parsedError = RedBeeError.safeParse(error);
    const errorData = parsedError.success === true ? parsedError.data : undefined;
    Log.player.error("Can't load the asset. Error: ", (error as Record<string, unknown>).toString());

    const products =
      this.asset$.value?.resource.embed?.playerType === "MEDIA" ? this.asset$.value?.resource.embed?.products : [];
    if (errorData?.code === 403 && errorData?.message === "VPN_BLOCKED") {
      navigationStack.pushPage(new VPNPlayerErrorPage(this));
    } else if (
      (errorData?.code === 403 || errorData?.status === 403) &&
      products.length > 0 &&
      PremiumHelper.isPurchased(products) === false
    ) {
      showErrorRetryPopup(
        () => {
          if (navigationStack.topPage instanceof PlayerPage) {
            navigationStack.removePage(navigationStack.topPage);
          }
          navigationStack.pushPage(new OffrePremiumPage());
        },
        undefined,
        undefined,
        t("error.descSubscribe"),
        t("error.button.subscribe")
      );
    } else if (catchError) {
      showErrorRetryPopup(
        reloadFn,
        undefined,
        undefined,
        errorData && errorData.message !== null
          ? `${errorData.message} (${errorData.code ?? errorData?.status})`
          : t("error.desc")
      );
    }
  }

  getPreferedAudioTrack() {
    return this.audioTracks().find(item => {
      const userPref = DevicePreferenceHelper.cachedPreferences()["audio"];
      const track = item.track;
      let lang = track.language;
      if (lang != null) {
        lang = lang.toUpperCase();
        if (userPref === LanguageSelectionButtons.QAD) {
          return track.raw?.roles?.includes("alternate") === true;
        } else if (userPref === LanguageSelectionButtons.QAA) {
          return track.label?.includes("Version originale") === true;
        } else {
          return lang === userPref;
        }
      }
    });
  }

  getPreferedTextTrack() {
    return this.subtitleTracks().find(item => {
      const track = item.track;
      const userPref = DevicePreferenceHelper.cachedPreferences()["subtitle"];
      let lang = track.language;
      if (lang != null) {
        lang = lang.toUpperCase();
        if (userPref === LanguageSelectionButtons.QHH) {
          return track.raw?.roles?.includes("caption");
        } else {
          return lang === userPref;
        }
      }
    });
  }

  protected _updateCurrentLiveAsVod(): void {
    const asset = this.asset$.value;
    if (asset === undefined) return;
    asset.isLive = () => false;
    asset.type = "MEDIA";
    this.asset$.value = asset;
    this.updateType();
  }

  protected _livePlayerType(): PlayerStreamType {
    if (this.asset$.value?.resource.isAdReplacement === true) {
      return PlayerStreamType.VIDEO_LIVE_AD_REPLACEMENT;
    } else if (this.asset$.value?.isFastTV === true) {
      return PlayerStreamType.VIDEO_FAST_TV;
    }
    return PlayerStreamType.VIDEO_LIVE;
  }

  /**
   * Handle PROGRAM_CHANGED event on fast tv to update metadata (live)
   * @param {unknown} data
   */
  protected _handleProgramChanged(data: unknown) {
    try {
      const parsedData = ProgramChangedData.parse(data);
      const assetId = parsedData.program?.asset?.assetId;

      if (typeof assetId !== "string") return;
      void this._loadAsset(assetId, "LIVE");
    } catch (e) {
      Log.player.error("Program changed parsing error", e, data);
    }
  }

  /**
   * Handle binge actions from two contexts : player page and sticky player for podcasts
   */
  binge(options: BingeOptions): void {
    const nextAsset = this.asset$.value?.resource?.embed?.next;
    // Reset previous binge listener
    this._bingeUnReg?.();

    if (options.context === "playerPage") {
      if (DevicePreferenceHelper.preferences().bingeEnable === false) return;
      let timerCallbackCalled = false;
      this._bingeUnReg = this.currentTime$.didChange(value => {
        if (this._disableNextBinge === false && nextAsset !== undefined && this.duration$.value - value <= 1) {
          void this.loadNextAsset(nextAsset, true);
        }
        if (this.duration$.value - value <= options.timerDelay && timerCallbackCalled === false) {
          timerCallbackCalled = true;
          options.timerCallback();
        }
      }, null);
    } else if (options.context === "stickyPlayer") {
      this._bingeUnReg = this.state$.didChange(
        state => {
          if (state === PlayerState.ENDED) {
            if (
              this._disableNextBinge === false &&
              nextAsset &&
              DevicePreferenceHelper.preferences().bingeEnable === true
            ) {
              void this.loadNextAsset(nextAsset, true);
            } else if (this.state$.value !== PlayerState.IDLE) {
              void this.destroy();
            }
          }
        },
        null,
        true
      );
    }
  }

  async loadNextAsset(media: RTBF.MediaCard | RTBF.TvLiveCard, auto: boolean) {
    this.trackAnalytics(`binge_watching_next_${auto ? "auto" : "manual"}`, {
      boolean_value: true,
    });
    void this.destroy();
    await this.load(media.id, media.resourceType);
    this.play(true);
  }

  disableNextBinge() {
    this._disableNextBinge = true;
  }
}
