import "./playerPage.scss";

import { FastTVLogo } from "~/components/player/fastTV/FastTVLogo";
import { APIGigyaOIDC } from "~/datas/api/apiGigyaOIDC";
import { DesactivateParentalPopupPage } from "~/pages/parentalControl/parentalPopupPage";
import { deeplinkManager } from "~/tools/deeplinkManager";
import { NetworkManager } from "~/tools/networkManager";
import { ParentalControlHelper } from "~/tools/parentalControlHelper";
import { StatusError } from "~/utils/errors";

import { PlayerAds } from "../../components/player/ads/playerAds";
import { FwdButtonPlayer } from "../../components/player/controls/buttons/fwdButtonPlayer";
import { RwdButtonPlayer } from "../../components/player/controls/buttons/rwdButtonPlayer";
import { PlayerControls } from "../../components/player/controls/playerControl/playerControl";
import { PlayerPanel } from "../../components/player/panel/playerPanel";
import { PlayerTitle } from "../../components/player/title/playerTitle";
import { Config } from "../../config";
import { DS } from "../../libs";
import { Listenable, throttle } from "../../libs/exports";
import { navigationStack } from "../../main";
import {
  IPlayer,
  IPlayerLoadOptions,
  playerAudio,
  PlayerState,
  playerVideo,
  WRAPPER_AUDIO_ID,
} from "../../tools/player";
import { PlayHistoryHelper } from "../../tools/playHistoryHelper";
import { EmbedType, MediaType, Rating } from "../../utils/rtbf/models";
import { GenericIncentivePageFull, IncentiveType } from "../incentive/incentivePage";
import { NoAccessPlayerErrorPage, NoPortabilityPlayerErrorPage } from "./playerErrorPage";

enum PlayerContentType {
  DIRECT = "direct",
  VOD = "vod",
  VOD_PREMIUM = "vodPremium",
}

enum PlayerPageComponent {
  title = "title",
  controls = "controls",
  panel = "panel",
  ads = "ads",
  fastTVLogo = "fastTVLogo",
}

export const HandledPlayerErrors = {
  102: 102,
  103: 103,
  104: 104,
  105: 105,
};

export class PlayerPage extends DS.View implements DS.IPage {
  private _loadOptions?: IPlayerLoadOptions;
  private _type$ = new Listenable(PlayerContentType.VOD);
  private _embedType: EmbedType;
  private _spinner: HTMLElement;
  private _resumeWhenOnline = false;

  protected _bingeShownPercentage = 95;
  protected _bingeShownEndingSecondsDelay = 11;
  protected _endingBinge$ = new DS.Listenable(false);
  protected _restart = false;
  protected _player: IPlayer;
  protected _modelSource$ = new DS.ModelSource<PlayerPageComponent>([]);
  protected _playerControls?: PlayerControls;

  protected constructor(player: IPlayer, mediaType: MediaType, embedType: EmbedType, loadOptions?: IPlayerLoadOptions) {
    super("PlayerPage", `playerPage ${mediaType}`);
    this._player = player;
    this._embedType = embedType;
    this._loadOptions = loadOptions;

    // Spinner
    this._spinner = DS.DOMHelper.createElement({
      tagName: "div",
      parent: this.rootElement,
      className: "spinner",
    });

    this._type$.didChange((value, oldValue) => {
      this.rootElement.classList.remove(oldValue);
      this.rootElement.classList.add(value);
    }, this);

    this._player.showControls$.didChange(value => {
      if (value) {
        this._loadControls();
      }
    }, this);

    this._player.showSpinner$.didChange(
      value => {
        value ? this._showSpinner() : this._hideSpinner();
      },
      this,
      true
    );

    this._player.currentTime$.didChange(currentTime => {
      // Current offset tracker
      const id = player.asset$.value?.resource.id;

      if (id !== undefined && currentTime !== undefined && currentTime !== 0) {
        PlayHistoryHelper.createOrUpdateOffset(id, currentTime);
      }
    }, this);

    this._player.asset$.didChange(
      (newAsset, oldAsset) => {
        if (newAsset) {
          if (this._player.isLive() === true) {
            this._type$.value = PlayerContentType.DIRECT;
          } else if (newAsset.isPremium() === true) {
            this._type$.value = PlayerContentType.VOD_PREMIUM;
          } else {
            this._type$.value = PlayerContentType.VOD;
          }

          // Don't need to reload and display the UI if it's the same asset (on fast TV)
          if (newAsset.isFastTV === false) {
            this._loadControls();
          } else if (!oldAsset || newAsset.resource.id !== oldAsset.resource.id) {
            this._loadControls();
          }
        }
      },
      this,
      true
    );

    this._binge();

    NetworkManager.isOnline$.didChange(
      isOnline => {
        if (isOnline === false) {
          if (this._player.state$.value === PlayerState.PLAYING) {
            this._resumeWhenOnline = true;
          }
          this._player.pause();
        } else if (this._resumeWhenOnline === true) {
          this._resumeWhenOnline = false;
          this._player.play();
        }
      },
      this,
      false
    );

    // List of controls. Needs to be at the end of the constructor
    // So the audio player can restart without destroying the player.
    this.delegate = DS.createListComponent({
      id: "PlayerList",
      className: "",
      modelSource$: this._modelSource$,
      viewFactory: elem => {
        switch (elem) {
          case PlayerPageComponent.title:
            return new PlayerTitle(player);
          case PlayerPageComponent.controls:
            return (this._playerControls = new PlayerControls(player));
          case PlayerPageComponent.panel:
            return new PlayerPanel(player, {
              mediaType,
              endingBinge$: this._endingBinge$,
            });
          case PlayerPageComponent.ads:
            return new PlayerAds(player);
          case PlayerPageComponent.fastTVLogo:
            return new FastTVLogo(this._player.asset$.value?.resource.embed?.channel?.logoFast);
        }
      },
      scrollingMode: { type: DS.ScrollingType.none, horizontal: false },
      scrollDuration: Config.scrollDuration,
      mouseSupport: Config.mouseSupport,
      noTransform: true,
    });
  }

  protected async _load(assetID: string) {
    try {
      await this._player.load(assetID, this._embedType, {
        collector: this,
        restart: this._restart,
        ...this._loadOptions,
      });
      this._player.play(true, this._restart);
    } catch (error: unknown) {
      if (error instanceof StatusError) {
        switch (error.statusCode) {
          case HandledPlayerErrors[102]:
            navigationStack.removePage(navigationStack.topPage);
            navigationStack.pushPage(new GenericIncentivePageFull(IncentiveType.connexion));
            break;
          case HandledPlayerErrors[103]:
            navigationStack.removePage(navigationStack.topPage);
            navigationStack.pushPage(new NoAccessPlayerErrorPage(this._player));
            break;
          case HandledPlayerErrors[104]:
            navigationStack.removePage(navigationStack.topPage);
            navigationStack.pushPage(new NoPortabilityPlayerErrorPage(this._player));
            break;
          case HandledPlayerErrors[105]:
            Log.app.error("Player parental control error :", error);
            navigationStack.removePage(navigationStack.topPage);
            break;
          default:
            Log.app.error("Player Loading StatusError :", error);
            break;
        }
      } else {
        Log.app.error("Player Loading Error :", error);
      }
    }
  }

  private _hideSpinner() {
    this._spinner.style.opacity = "0";
  }

  private _showSpinner() {
    this._spinner.style.opacity = "1";
  }

  protected _loadControls() {
    this._modelSource$.value = [
      ...(this._player.asset$.value?.isFastTV === true ? [PlayerPageComponent.fastTVLogo] : []),
      PlayerPageComponent.title,
      PlayerPageComponent.controls,
    ];
  }

  private _binge() {
    /* init binge listenables when asset changed : 
    - a first binge view is displayed at 95% (_bingeShownPercentage)
    - a second one at 11s before the end (_bingeShownEndingSecondsDelay)
    Only one of each will be displayed.
    Example : if user goes to 96%, the binge view is shown then user clicks "pas maintenant" and goes back to 90%, the Binge view will not be redisplayed at 95%.
    */
    let beforeEndingBingeUnregister: (() => void) | undefined = undefined;
    let endingBingeUnregister: (() => void) | undefined = undefined;
    this._player.asset$.didChange(
      () => {
        let beforeEndingBingeCantBeShown = false;
        beforeEndingBingeUnregister?.();
        endingBingeUnregister?.();
        if (this._player.asset$.value?.isLive() === false && this._hasNextMedia("binge")) {
          beforeEndingBingeUnregister = this._player.percentRatio$.didChange(value => {
            if (value >= this._bingeShownPercentage && beforeEndingBingeCantBeShown === false) {
              this._endingBinge$.value = false;
              this._loadPanel();
              beforeEndingBingeCantBeShown = true;
            }
            if (value < this._bingeShownPercentage) {
              beforeEndingBingeCantBeShown = false;
            }
          }, this);

          endingBingeUnregister = this._player.currentTime$.didChange(value => {
            if (
              Math.floor(this._player.duration$.value - value) <= this._bingeShownEndingSecondsDelay &&
              this._player.duration$.value > 0
            ) {
              this._endingBinge$.value = true;
              this._loadPanel();
              endingBingeUnregister?.();
            }
          }, this);
        }
      },
      this,
      true
    );
  }

  // Created here for the _binge function, but the behavior is defined in audio and video classes
  protected _loadPanel() {}

  // media ID en optional ? Peut-être une bonne idée de le forcer partout
  static async playAsset(
    assetID: string,
    mediaType: MediaType = "VIDEO",
    embedType: EmbedType,
    restart = false,
    options?: {
      loadOptions?: IPlayerLoadOptions;
      rating?: Rating;
    }
  ) {
    if (APIGigyaOIDC.isConnected() === false) {
      navigationStack.pushPage(
        new GenericIncentivePageFull(IncentiveType.connexion, () => {
          void this.playAsset(assetID, mediaType, embedType, restart, options);
        })
      );
      return;
    }
    const play = () => {
      navigationStack.pushPage(
        mediaType === "AUDIO"
          ? new AudioPlayerPage(assetID, embedType, restart, options?.loadOptions)
          : new VideoPlayerPage(assetID, embedType, restart, options?.loadOptions)
      );
    };
    const authorized =
      options?.rating !== undefined ? await ParentalControlHelper.isParentalAuthorized(options?.rating) : true;

    if (authorized) {
      play();
    } else {
      navigationStack.pushPage(
        new DesactivateParentalPopupPage(() => {
          play();
        })
      );
    }
  }

  onNav(key: DS.Keys) {
    switch (key) {
      case DS.Keys.rewind:
        RwdButtonPlayer.rewind() ?? this._player.rewind();
        return true;
      case DS.Keys.forward:
        FwdButtonPlayer.forward() ?? this._player.forward();
        return true;
      case DS.Keys.play:
        this._player.play();
        return true;
      case DS.Keys.pause:
        this._player.pause();
        return true;
      case DS.Keys.playPause:
        this._player.isPlaying() ? this._player.pause() : this._player.play();
        return true;
      case DS.Keys.back:
        return deeplinkManager.exitDeeplinkPage();
    }
    return false;
  }

  protected _hasNextMedia(type?: "binge" | "recommended") {
    if (type !== undefined && type === "binge") {
      return this._player.asset$.value?.resource?.embed?.next !== undefined;
    } else if (type !== undefined && type === "recommended") {
      return this._player.asset$.value?.recommended !== undefined;
    } else {
      return (
        this._player.asset$.value?.resource?.embed?.next !== undefined ||
        this._player.asset$.value?.recommended !== undefined
      );
    }
  }
}

class VideoPlayerPage extends PlayerPage {
  private _previousState: PlayerState;
  private _uiTimeout?: number;
  private _uiVisible$ = new Listenable(true);
  private _throttledShowUi = throttle(
    () => {
      this._showUI();
    },
    250,
    "immediate"
  ).func;

  constructor(assetID: string, embedType: EmbedType, restart = false, loadOptions?: IPlayerLoadOptions) {
    super(playerVideo, MediaType.enum.VIDEO, embedType, loadOptions);
    void playerAudio.destroy();
    this._restart = restart;
    const videoCtn = this.rootElement.insertBefore(
      DS.DOMHelper.createElement({ tagName: "div", parent: this.rootElement, id: "PlayerVideo" }),
      this.rootElement.firstChild || null
    );
    this._player.attach(videoCtn);
    this._player.autoplay = true;
    this._player.muted = false;
    this._previousState = PlayerState.IDLE;

    videoCtn.onmousemove = this.mousemoveHandler;

    this._player.state$.didChange(state => {
      if (this._previousState === PlayerState.SEEKING) {
        this._showUI();
      }
      if (state === PlayerState.LOADING) {
        this._player.applySubtitleStyle();
      }
      this._previousState = state;
    }, this);

    this._player.adReplacement$.didChange(value => {
      if (value !== undefined) {
        this._loadAds();
      } else {
        this._loadControls();
      }
    }, this);

    /**
     * When content end time is over :
     * 1) Do nothing on Fast TVs
     * 2) Remove PlayerPage and destroy video player if :
     *    a) There's no next media
     *    b) It's an ad replaced live with on recommandations
     *    c) It's a media with binge (binge panel was already shown at 95% of the media and 11 seconds before the end)
     * 3) Show recommandations, and pause content if it's live (live content can play longer than given
     *    end time and it cause problems)
     */
    this._player.percentRatio$.didChange(value => {
      if (this._player.asset$.value?.isFastTV === true) return;
      if (value >= 100) {
        // To not stop the video before the end. Analytics events need to be sent
        setTimeout(() => {
          if (navigationStack.topPage instanceof PlayerPage) {
            const isAdReplacementWithoutReco =
              this._hasNextMedia("recommended") === false &&
              this._player.asset$.value?.resource.isAdReplacement === true;
            const isMediaWithBinge =
              this._player.asset$.value?.type === "MEDIA" && this._hasNextMedia("binge") === true;
            if (this._hasNextMedia() === false || isAdReplacementWithoutReco === true || isMediaWithBinge === true) {
              navigationStack.removePage(navigationStack.topPage);
              void this._player.destroy();
              return;
            }
          }
          this._loadPanel();
          if (this._player.asset$.value?.isLive() === true) {
            this._player.pause();
          }
        }, 200);
      }
    }, this);
    void this._load(assetID);
  }

  protected _loadControls() {
    super._loadControls();
    this._showUI();
  }

  protected _loadPanel() {
    super._loadPanel();
    this._modelSource$.value = [PlayerPageComponent.title, PlayerPageComponent.panel];
    this._player.showControls$.value = false;
    this._showUI();
  }

  private _loadAds() {
    this._modelSource$.value = [PlayerPageComponent.ads];
    this._player.showControls$.value = false;
    this._showUI();
  }

  private _hideUI() {
    if (this._player.state$.value === PlayerState.SEEKING) {
      this._showUI();
    } else {
      this._uiVisible$.value = false;
      this.delegate?.onHidden?.();
    }
  }

  private _showUI() {
    if (!this._uiVisible$.value) {
      this._uiVisible$.value = true;
      this.delegate?.onShown?.();
      this._positionSubtitles();
    }
    window.clearTimeout(this._uiTimeout);
    if (
      !this._modelSource$.value.includes(PlayerPageComponent.panel) &&
      !this._modelSource$.value.includes(PlayerPageComponent.ads)
    ) {
      this._uiTimeout = window.setTimeout(() => {
        this._hideUI();
        this._positionSubtitles();
      }, 6000);
    }
  }

  private _positionSubtitles() {
    this._player.positionSubtitles(this._playerControls?.rootElement);
  }

  onRelease() {
    super.onRelease();
    void this._player.destroy();
  }

  onNav(key: DS.Keys) {
    if (!this._modelSource$.value.includes(PlayerPageComponent.panel)) this._showUI();
    switch (key) {
      case DS.Keys.back:
        if (this._player.isLoading()) {
          this._player.registerBackIntent();
        } else {
          void this._player.destroy();
        }
        break;
    }
    return super.onNav(key);
  }

  onMouseWheel() {
    if (!this._modelSource$.value.includes(PlayerPageComponent.panel)) this._showUI();
    return false;
  }

  mousemoveHandler = (ev: MouseEvent): void => {
    // Height of TV screen is 1080, so 880 (200px) of the bottom should be a good boundary for the cursor to reach when we want to show the UI controls again.
    if (ev.y > 830) {
      this._throttledShowUi();
    }
  };
}

class AudioPlayerPage extends PlayerPage {
  constructor(assetID: string, embedType: EmbedType, restart = false, loadOptions?: IPlayerLoadOptions) {
    super(playerAudio, MediaType.enum.AUDIO, embedType, loadOptions);
    this._restart = restart;
    const playerContainerElt = document.getElementById(WRAPPER_AUDIO_ID);

    if (playerContainerElt !== null) {
      this._player.attach(playerContainerElt, true);
    } else {
      Log.app.error(WRAPPER_AUDIO_ID + " div is not present. Should not happens!");
    }
    this._player.autoplay = true;
    this._player.muted = false;
    if (this._player.asset$.value?.resource.assetId !== assetID) {
      void this._load(assetID);
    }

    const background = DS.DOMHelper.createElement({
      tagName: "img",
      parent: this.rootElement,
      className: "background",
      style: {
        display: "none",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
    });
    this._player.asset$.didChange(
      asset => {
        if (asset?.resource?.background.m !== undefined) {
          background.src = asset.resource.background.m;
          background.style.display = "block";
        } else {
          background.src = "";
          background.style.display = "none";
        }
      },
      this,
      true
    );

    this._player.state$.didChange(
      state => {
        if (state === PlayerState.PAUSED && this._player.currentTime$.value === 0 && this._restart === false) {
          this._player.play(false, this._restart);
        }
        if (state === PlayerState.ENDED && navigationStack.topPage instanceof PlayerPage) {
          if (this._hasNextMedia("recommended") === true) {
            this._loadPanel();
          } else {
            navigationStack.removePage(navigationStack.topPage);
            // Use a setTimeout to stack the destroy instruction after the completion of the removePage
            window.setTimeout(() => {
              void this._player.destroy();
            }, 1);
          }
        }
      },
      this,
      true
    );
  }

  protected _loadPanel() {
    super._loadPanel();
    if (!this._modelSource$.value.includes(PlayerPageComponent.panel)) {
      this._modelSource$.value = [PlayerPageComponent.title, PlayerPageComponent.panel];
    }
    this._player.showControls$.value = false;
  }
}
